import React,{
  useEffect,useState
} from 'react'

import ReactSession from "../../components/ReactSession";

import CalendarE from './CalendarE';

function CalenderEmp() {
    ReactSession.setStoreType("localStorage");

  const default_btns = [
    {
        id:'Calendar',
      
    },
    {
        id:'Pending requests',
    }
    ,
    {
        id:'Mandatory leave',
        
    }
]


const [Panel,setPanel] = useState('')
    const handleBtns  = (btn) =>{
        console.log(btn);
      

        setPanel(btn.id)

        console.log(document.getElementsByClassName('btn-b'))
        const arr = [...document.getElementsByClassName('btn-b')]
        console.log(arr)
        arr.forEach((ele)=>{
            ele.style.fontWeight = '400'
            ele.style.borderBottom = 'none'
        })
        document.getElementById(`${btn.id}`).style.fontWeight = '600'
        document.getElementById(`${btn.id}`).style.borderBottom = '2px solid #073a5d'

        // document.getElementById(`${btn.id}`).style.color = '600'


    }

    useEffect(()=>{
        setPanel('Calendar')
    },[])


  return (
    <div className='emp_p' >

    <div>
        <h2>Calendar</h2>
    </div>


    <div>

    <div className='btns-emp'>


    {
        default_btns.map((btn,index)=>{
            return (
                <>
                {/* onClick={()=>(window.location.href=`/Architecture/Employees/${btn.link}`)} */}
                <button className='btn-b' key={index} onClick={()=>handleBtns(btn)} id={btn.id}>
                    {btn.id}
                </button>

                

                </>

            )
        })


       
    }


    </div>


    {

        Panel === 'Calendar'?
        <CalendarE/>
        :
        Panel === 'Pending requests'?
        'd d'
        :
        Panel === 'Mandatory leave'?
        'kdk'
    
        :''

    }



    </div>
    
    
    
    </div>
  )
}

export default CalenderEmp