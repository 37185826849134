import React from 'react'

function Actions() {
  return (
    <div className='doc-p'>
    <h3>Actions</h3>


    <div className='row mt-2 mb-2'>

    <div className="row gap-3">

  
    <h6 className='mt-3'>Add time off</h6>

<div onClick={()=>window.location.href=`/Architecture/Hr/Actions/AddAnnualLeave`} className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)',cursor:'pointer'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-sun text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Add annual leave</p>

</div>

</div>

</div>




<div  onClick={()=>window.location.href=`/Architecture/Hr/Actions/AddSickness`}  className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

{/* <i className="fas fa-head-side-cough text-primary" style={{fontSize:'1.5rem'}}></i> */}
<i className="fas fa-band-aid text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Add sickness</p>

</div>

</div>

</div>



<div  onClick={()=>window.location.href=`/Architecture/Hr/Actions/AddLateness`}  className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-user-clock text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Add lateness</p>

</div>

</div>

</div>



<div  onClick={()=>window.location.href=`/Architecture/Hr/Actions/AddOtherAbsence`}  className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-chair text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Add other absence</p>

</div>

</div>

</div>




</div>


<div className="row gap-3">

  
<h6 className='mt-3'>HR documents</h6>

<div onClick={()=>window.location.href=`/Architecture/Hr/Actions/RequestAnAppointment`} className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-calendar-alt text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Request an appointment</p>

</div>

</div>

</div>


</div>




<div className="row gap-3">

  
<h6 className='mt-3'>Overtime</h6>

<div className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-history text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Log overtime</p>

</div>

</div>

</div>



<div className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-clock text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Use TOIL balance</p>

</div>

</div>

</div>


</div>





<div className="row gap-3">

  
<h6 className='mt-3'>Employees & teams</h6>

<div onClick={()=>window.location.href=`/Architecture/Hr/Employees/Employees`} className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-user text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Add employees</p>

</div>

</div>

</div>



<div onClick={()=>window.location.href=`/Architecture/Hr/Employees/Manage teams`} className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-users text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Manage teams</p>

</div>

</div>

</div>




<div onClick={()=>window.location.href=`/Architecture/Hr/Actions/AddJobs`} className="col-5 p-2" style={{border:'1px solid rgba(0,0,0,0.1)',borderRadius:'0.3rem',boxShadow:'4px 5px 15px rgba(0,0,0,0.07)'}}>
<div className="row align-items-center justify-content-center">

<div style={{width:'65px',height:'65px',borderRadius:'50%',backgroundColor:'#EBF7FF'}} className="d-flex justify-content-center align-items-center col-4">

<i className="fas fa-briefcase text-primary" style={{fontSize:'1.5rem'}}></i>
</div>
<div className="col-8">
<p className="text-primary" style={{fontSize:'1.1rem',fontWeight:'500'}}>Add job</p>

</div>

</div>

</div>


</div>



    

    </div>
  </div>
  )
}

export default Actions