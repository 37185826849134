import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactSession from "../../../components/ReactSession";


function MyDocuments(props) {
  ReactSession.setStoreType("localStorage");
  // let params_dir = props.d?props.d:0;
  const { employee_id } = useParams();

  const folderModalClose = React.useRef(null);
  const fileModalClose = React.useRef(null);
  const [rootDir, setRootDir] = useState(null);
  const [dirFolder, setDirFolder] = useState([]);
  const [dirFiles, setDirFiles] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [search, setSearch] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchDirectories();
  }, [rootDir]);

  const fetchEmployeeDir = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_documents/fetch_employee_dir`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            emp_id: employee_id,
            server_id: ReactSession.get('server_id')
          },
        }
      );

      if (response?.data && response?.data?.data) {
        setRootDir(response?.data?.data.id)
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }
  const fetchDirectories = async () => {
    if (!rootDir) {
      return fetchEmployeeDir()
    }
    setDirFolder([]);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_documents/fetch`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            dirId: rootDir,
            server_id: ReactSession.get('server_id'),
          },
        }
      );

      if (response?.data && response?.data?.data) {
        if (
          response.data.data?.folder &&
          response.data.data?.folder.length > 0
        ) {
          if (rootDir === 0) {
            const dataByKeys = {};
            response.data.data.folder.forEach(item => {
              if (!dataByKeys[item.file_type]) dataByKeys[item.file_type] = []
              dataByKeys[item.file_type].push(item);
            })
            setDirFolder(dataByKeys);
          } else {
            setDirFolder(response.data.data?.folder);
          }
        } else setDirFolder([]);
        if (response.data.data?.files && response.data.data?.files.length > 0) {
          setDirFiles(response.data.data?.files);
        } else setDirFiles([]);
        if (
          response.data.data?.breadcrumb &&
          response.data.data?.breadcrumb.length > 0
        ) {
          setBreadcrumb(response.data.data?.breadcrumb.filter(item => item.folder_name !== "My Documents"));
        } else setBreadcrumb([]);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const chanaleFolder = async (id) => {
    setRootDir(id);
  };
  const handleFolderCreation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_documents/createFolder`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            server_id: ReactSession.get('server_id'),
            newFolderName: newFolderName,
            rootDir: rootDir,
            breadcrumb: breadcrumb,
          },
        }
      );
      folderModalClose.current?.click();
      fetchDirectories();
      if (response?.data?.res > 0) {
        toast.success("Folder Saved!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.warning("Folder Allready exists!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      folderModalClose.current?.click();
      toast.danger("Error occurred!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.error("Error saving employee:", error);
    }
  };
  const handleUploadFile = async (e) => {
    e.preventDefault();
    setBtnLoad(true)
    const formData = new FormData();
    formData.append("rootDir", rootDir);
    formData.append("server_id", ReactSession.get('server_id'));

    let dirString = "";
    breadcrumb.map((data) => (dirString = dirString + "/" + data.folder_name));
    formData.append("breadcrumb", dirString);
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_documents/fileUpload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              ((progressEvent.loaded / progressEvent.total) * 100) - 15
            );
            setUploadProgress(progress);
          },
        }
      );
      fileModalClose.current?.click();
      fetchDirectories();
      setBtnLoad(false)
      if (response?.data?.res > 0) {
        setUploadProgress(100);
        toast.success("File Saved!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.warning("Something Went Wrong!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      fileModalClose.current?.click();
      setBtnLoad(false)
      console.error("Error saving employee:", error);
      toast.danger("Error occurred!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const handleMultipleChange = (e) => {
    e.preventDefault();
    setFiles(e.target.files);
  };

  const handelFolderDelete = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_documents/folderDelete`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            server_id: ReactSession.get('server_id'),
            id: id
          },
        }
      );
      fetchDirectories();
      if (response?.data?.res > 0) {
        toast.success("Folder Deleted!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.warning("Something Went Wrong!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.danger("Error occurred!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.error("Error saving employee:", error);
    }
  };
  const handelFileDelete = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_documents/fileDelete`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            server_id: ReactSession.get('server_id'),
            id: id
          },
        }
      );
      fetchDirectories();
      if (response?.data?.res > 0) {
        toast.success("File Deleted!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.warning("Something Went Wrong!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.danger("Error occurred!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.error("Error saving employee:", error);
    }
  };
  const getReadbleFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  const handleCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link Copied!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.danger("Failed to copy!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };
  return (
    <div>
      {/* <nav className="mt-3 mb-3" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/Architecture/Hr/Documents/AllFolders">All folders</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My documents
          </li>
        </ol>
      </nav> */}

      {rootDir === 0 ? (
        <div className="row mt-2 mb-2">
          {dirFolder ?

            (
              <>
                {Object.keys(dirFolder).map((key) => {
                  return <div className="row gap-3" key={key}>
                    <h6 className="mt-3">{key}</h6>
                    {dirFolder[key].map((data, index) => {
                      return (
                        <div
                          key={data.id}
                          onDoubleClick={() => chanaleFolder(data.id)}
                          className="col-5 p-2"
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderRadius: "0.3rem",
                            boxShadow: "4px 5px 15px rgba(0,0,0,0.07)",
                            cursor: "pointer",
                          }}
                        >
                          <div className="row align-items-center justify-content-center">
                            <div
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: "50%",
                                backgroundColor: "#EBF7FF",
                              }}
                              className="d-flex justify-content-center align-items-center col-4"
                            >
                              <i
                                className="fas fa-folder text-primary"
                                style={{ fontSize: "1.5rem" }}
                              ></i>
                            </div>
                            <div className="col-8">
                              <p
                                className="text-primary"
                                style={{ fontSize: "1.1rem", fontWeight: "500" }}
                              >
                                {data.folder_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                })}
              </>
            ) : (
              "No Folder Found"
            )}
        </div>
      ) : (
        <>
          <div className="row mt-2 mb-2">
            <div className="col-9">
              <input
                placeholder="Search My documents..."
                className="form-control"
                style={{
                  border: "2px solid rgba(0,0,0,0.3)",
                  width: "500px",
                  color: "black",
                }}
                onChange={handleSearch}
              />
            </div>

            <div className="col-3">
              <div className="d-flex gap-1 justify-content-center align-items-center">
                <span className="" style={{ fontSize: "1rem" }}>
                  View
                </span>
                <select className="form-select">
                  <option>10</option>
                  <option>20</option>
                </select>
                <span style={{ fontSize: "1rem", width: "100%" }}>
                  per page
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-7">
              <button type="button" className="btn btn-primary" disabled>
                Download
              </button>
            </div>

            <div className="col-5 ">
              <div className="d-flex gap-1">
                <button
                  className="btn btn-primary col-4 "
                  style={{ fontSize: "0.9rem" }}
                  data-bs-toggle="modal"
                  data-bs-target="#newFile"
                >
                  <i className="fas fa-file-upload"></i> Upload
                </button>
                <button
                  className="btn btn-primary col-4 "
                  style={{ fontSize: "0.9rem" }}
                  data-bs-toggle="modal"
                  data-bs-target="#newFolder"
                >
                  <i className="fas fa-folder-plus"></i> New folder
                </button>
                <button
                  className="btn btn-primary col-4 "
                  style={{ fontSize: "0.9rem" }}
                >
                  Create report
                </button>
              </div>
            </div>
          </div>
          {/* ------------------------MODAL------------------------------ */}
          <div
            className="modal fade"
            id="newFolder"
            tabIndex="-1"
            aria-labelledby="newFolderLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="newFolderLabel">
                    Folder Name
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={folderModalClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="folder_name"
                      placeholder="Enter your folder name"
                      onChange={(e) => setNewFolderName(e.target.value)}
                    />
                    <label htmlFor="floatingInput">
                      Enter your folder name
                    </label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary px-5 float-end"
                    onClick={handleFolderCreation}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* --------------- */}
          <div
            className="modal fade"
            id="newFile"
            tabIndex="-1"
            aria-labelledby="newFileLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="newFileLabel">
                    Folder Name
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={fileModalClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <label
                    htmlFor=""
                    className="w-100 text-center img-upload-box"
                  >
                    <input
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      onChange={handleMultipleChange}
                    />
                  </label>
                  <button
                    type="button"
                    className="btn btn-primary px-5 float-end"
                    onClick={handleUploadFile}
                    disabled={btnLoad}
                  >
                    {btnLoad ? <>
                      <span classname="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                      <span role="status"> Uploading ...</span>
                    </> : 'Save'
                    }
                  </button>
                  {uploadProgress > 0 ? (
                    <>
                      <progress
                        className="progressBar"
                        value={uploadProgress}
                        max="100"
                      ></progress>{" "}
                      {uploadProgress}%
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------MODAL----------------------------- */}
          <div className="mt-3">
            <table className="table table-striped table-hover">
              <thead>
                <tr style={{ fontSize: "0.9rem" }}>
                  <th
                    scope="col"
                    style={{
                      color: "#073a5d",
                      backgroundColor: "#EBF7FF",
                      borderBottom: "2px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    <input type="checkbox" name="" id="" />
                  </th>
                  <th
                    scope="col"
                    style={{
                      color: "#073a5d",
                      backgroundColor: "#EBF7FF",
                      borderBottom: "2px solid rgba(0,0,0,0.2)",
                      width: "50%",
                    }}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    style={{
                      color: "#073a5d",
                      backgroundColor: "#EBF7FF",
                      borderBottom: "2px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    Date modified
                  </th>
                  <th
                    scope="col"
                    style={{
                      color: "#073a5d",
                      backgroundColor: "#EBF7FF",
                      borderBottom: "2px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    style={{
                      color: "#073a5d",
                      backgroundColor: "#EBF7FF",
                      borderBottom: "2px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    Size
                  </th>
                  <th
                    scope="col"
                    style={{
                      color: "#073a5d",
                      backgroundColor: "#EBF7FF",
                      borderBottom: "2px solid rgba(0,0,0,0.2)",
                    }}
                  >
                    Action
                  </th>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <nav className="breadcam-icon" aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <button
                            className="text-primary"
                            onClick={() => window.location.reload()}
                          >
                            My Documents
                          </button>
                        </li>
                        {breadcrumb &&
                          breadcrumb.map((data, index) => {
                            return (
                              <li
                                key={index}
                                className={`breadcrumb-item ${rootDir === data.id
                                  ? "active"
                                  : "text-primary"
                                  }`}
                                aria-current="page"
                              >
                                <button
                                  onDoubleClick={() => chanaleFolder(data.id)}
                                >
                                  {data.folder_name}
                                </button>
                              </li>
                            );
                          })}
                      </ol>
                    </nav>
                  </td>
                  <td colSpan={2}></td>
                </tr>
              </thead>
              <tbody style={{ fontSize: "0.9rem" }}>
                {dirFolder && dirFolder.length > 0
                  ? dirFolder
                    .filter((val) => {
                      return search.toLowerCase() === ""
                        ? val
                        : val?.folder_name.toLowerCase().includes(search);
                    })
                    .map((data, index) => {
                      return (
                        <tr
                          key={index}
                          onDoubleClick={() => chanaleFolder(data.id)}
                        >
                          <th scope="row">
                            <input type="checkbox" name="" id="" />
                          </th>
                          <td
                            className="text-primary"
                            style={{ fontWeight: "500" }}
                          >
                            <i className="bi bi-folder2-open fs-6"></i>{" "}
                            {data.folder_name}
                          </td>
                          <td>
                            {moment(data.updatedAt).isSameOrBefore(
                              moment().subtract(7, "days")
                            )
                              ? moment(data.updatedAt).format(
                                "MMMM Do YYYY, h:mm a"
                              )
                              : moment(data.updatedAt).fromNow()}
                          </td>
                          <td className="text-dark">{data.file_type}</td>
                          <td className="text-dark"></td>
                          <td>
                            <button
                              onClick={() => handelFolderDelete(data.id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
                {dirFiles && dirFiles.length > 0
                  ? dirFiles
                    .filter((val) => {
                      return search.toLowerCase() === ""
                        ? val
                        : val?.file_name.toLowerCase().includes(search);
                    })
                    .map((data, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            <input type="checkbox" name="" id="" />
                          </th>
                          <td
                            className="text-primary"
                            style={{ fontWeight: "500" }}
                          >
                            <a href={data.file_link} target="__blank">
                              <i
                                classname={`bi bi-filetype-${data.file_type} fs-6`}
                              ></i>
                              {data.file_name}
                            </a>
                            <button
                              className="btn"
                              onClick={() => handleCopy(data.file_link)}
                            >
                              <i classname="bi bi-link-45deg"></i>
                            </button>
                          </td>
                          <td>
                            {moment(data.updatedAt).isSameOrBefore(
                              moment().subtract(7, "days")
                            )
                              ? moment(data.updatedAt).format(
                                "MMMM Do YYYY, h:mm a"
                              )
                              : moment(data.updatedAt).fromNow()}
                          </td>
                          <td className="text-dark">{data.file_type} File</td>
                          <td className="text-dark">
                            {getReadbleFileSize(data.size)}
                          </td>
                          <td width={100}>
                            <button onClick={() => handelFileDelete(data.id)}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
                {/*  */}
              </tbody>
            </table>
            {(dirFolder && dirFolder.length > 0) ||
              (dirFiles && dirFiles.length > 0) ? (
              ""
            ) : (
              <div className="p-2 row mt-4 ">
                <div className="col-12 text-center d-flex flex-column align-items-center justify-content-center">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      backgroundColor: "rgba(0,0,0,0.1)",
                    }}
                  >
                    <i
                      className="fas fa-server "
                      style={{ fontSize: "3.5rem", color: "rgba(0,0,0,0.3)" }}
                    ></i>
                  </div>

                  <p style={{ fontSize: "1.1rem" }} className="mt-2">
                    This folder is empty
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default MyDocuments;
