import React, { useState } from "react";
import Maincalendar from "./Maincalendar";
import "./calendarstyle.css";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ReactSession from "../../components/ReactSession";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function CalendarE() {
  ReactSession.setStoreType("localStorage");
  let query = useQuery();
  let isFilter = query.get("filter")?query.get("filter"):'all';
  const [filter, setFilter] = useState(isFilter)
  return (
    <div className="cal-con">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center"><h5>{moment().format('MMMM YYYY')}</h5> 
                <button className="text-primary ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#filter_box" aria-expanded="false" aria-controls="filter_box"><i className="bi bi-funnel"></i> Filter absences</button>
            </div>
            <a href="/Architecture/Hr/Employees/Details/0/CreateAnnualLeave" className="btn btn-danger btn-p m-0">Add time off</a>
          </div>
        </div>
        <div className={`col-12 my-1 collapse ${query.get("filter")?'show':''}`} id="filter_box">
        by Type: 
          <input type="radio" className="btn-check" name="check" id="btn-all" autocomplete="off" checked={filter==='all'?true:false}onChange={()=>setFilter('all')} />
          <label className="btn btn-outline-info ms-2" for="btn-all"><i className="bi bi-check2-circle"></i> All</label>

          <input type="radio" className="btn-check" name="check" id="btn-annual-leave" autocomplete="off" checked={filter==='casual'?true:false}onChange={()=>setFilter('casual')} />
          <label className="btn btn-outline-info ms-2" for="btn-annual-leave"><i class="bi bi-node-plus"></i> Annual leave</label>

          <input type="radio" className="btn-check" name="check" id="btn-sickness" autocomplete="off" checked={filter==='sickness'?true:false}onChange={()=>setFilter('sickness')} />
          <label className="btn btn-outline-info ms-2" for="btn-sickness"><i className="bi bi-bandaid"></i> Sickness</label>

          <input type="radio" className="btn-check" name="check" id="btn-lateness" autocomplete="off" checked={filter==='lateness'?true:false}onChange={()=>setFilter('lateness')} />
          <label className="btn btn-outline-info ms-2" for="btn-lateness"><i className="bi bi-watch"></i> Lateness</label>

          <input type="radio" className="btn-check" name="check" id="btn-Clockin" autocomplete="off" checked={filter==='clockIn'?true:false}onChange={()=>setFilter('clockIn')} />
          <label className="btn btn-outline-info ms-2" for="btn-Clockin"><i class="bi bi-clock-history"></i> Clockin</label>

          <input type="radio" className="btn-check" name="check" id="btn-overtime" autocomplete="off" checked={filter==='overtime'?true:false}onChange={()=>setFilter('overtime')} />
          <label className="btn btn-outline-info ms-2" for="btn-overtime"><i class="bi bi-clock-history"></i> Overtime</label>

          <input type="radio" className="btn-check" name="check" id="btn-toil" autocomplete="off" checked={filter==='toil'?true:false}onChange={()=>setFilter('toil')} />
          <label className="btn btn-outline-info ms-2" for="btn-toil"><i class="bi bi-clock-history"></i> TOIL</label>

          <input type="radio" className="btn-check" name="check" id="btn-to-do" autocomplete="off" checked={filter==='to-do'?true:false}onChange={()=>setFilter('to-do')} />
          <label className="btn btn-outline-info ms-2" for="btn-to-do"><i className="bi bi-clipboard2-check"></i> Task to-do</label>

          <input type="radio" className="btn-check" name="check" id="btn-holidays" autocomplete="off" checked={filter==='holidays'?true:false}onChange={()=>setFilter('holidays')} />
          <label className="btn btn-outline-info ms-2" for="btn-holidays"><i className="bi bi-calendar2-week"></i> Holidays</label>
        </div>
        <div className="col-md-12">
          <div className="d-flex mb-4 text-end justify-content-start">
          <div className="ms-4">
              <span
                className="d-inline-block rounded-pill"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "rgb(99 187 247)",
                }}
              ></span>
              <b> Annual leave</b>
            </div>
            <div className="ms-4">
              <span
                className="d-inline-block rounded-pill"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "rgb(218 163 39)",
                }}
              ></span>
              <b> Sickness</b>
            </div>
            <div className="ms-4">
              <span
                className="d-inline-block rounded-pill"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "rgb(208 77 86)",
                }}
              ></span>
              <b> Lateness</b>
            </div>
            <div className="ms-4">
              <span
                className="d-inline-block rounded-pill"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "#f5c4d9",
                }}
              ></span>
              <b> Clock In \ Out</b>
            </div>
            <div className="ms-4">
              <span
                className="d-inline-block rounded-pill"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "rgb(208 77 86)",
                }}
              ></span>
              <b> Overtime</b>
            </div>
            <div className="ms-4">
              <span
                className="d-inline-block rounded-pill"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "rgb(23 134 48)",
                }}
              ></span>
              <b> Toil</b>
            </div>
            <div className="ms-4">
              <span
                className="d-inline-block rounded-pill"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "rgb(113 165 153)",
                }}
              ></span>
              <b> To-do</b>
            </div>
            <div className="ms-4">
              <span
                className="d-inline-block rounded-pill"
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "rgb(216 1 109)",
                }}
              ></span>
              <b> Holidays</b>
            </div>
          </div>
        </div>
      </div>
      <Maincalendar filter={filter} />
    </div>
  );
}

export default CalendarE;
