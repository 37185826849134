import React, { useState } from 'react';
import './TruncatedParagraph.css'; // CSS file for styling

const TruncatedParagraph = ({ text, maxLength , maxHeight }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  // Toggle expand state
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      setIsExpanded(true);
      return text;
    }
    return text.substr(0, maxLength) + '...';
  };

  return (
  
    <div style={{maxHeight: maxHeight}} className={`truncated-paragraph ${isExpanded && 'expanded'}`}> 
      <p>
        {isExpanded ? text : truncateText(text, maxLength)}
        {!isExpanded && (
          <button className="read-more" onClick={toggleExpanded}>
            Read more
          </button>
        )}
      </p>
    </div>
  );
};

export default TruncatedParagraph;
