import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { calculateAge } from '../../../utils/DateFunctions'
import ReactSession from "../../../components/ReactSession";

export default function Create() {
  ReactSession.setStoreType("localStorage");
  const { employee_id } = useParams();
  const [formData, setFormData] = useState({
    server_id: ReactSession.get('server_id'),
    is_edit: false,
    employee_id: employee_id,
    full_name: "",
    role: "",
    known_as: "",
    email_id: "",
    phone_code: "",
    phone_number: "",
    dob: "",
    age: "",
    gender: "MALE",
    religion: "",
    nationality: "",
  });

  useEffect(() => {
    if (employee_id) {
      fetchEmployeeDetails();
    }
  }, [employee_id]);
  const fetchEmployeeDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_single_employee_details`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          server_id: ReactSession.get('server_id'),
          employee_id: employee_id,
        }
      });
      const { full_name, role, known_as, email_id, phone_code, phone_number, dob, age, gender, religion, nationality } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { full_name: '', role: '', known_as: '', email_id: '', phone_code: '', phone_number: '', dob: '', age: '', gender: '', religion: '', nationality: '' };
      setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, full_name: full_name, role: role, known_as: known_as, email_id: email_id, phone_code: phone_code, phone_number: phone_number, dob: dob, age: age, gender: gender, religion: religion, nationality: nationality });
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDob = (e) => {
    // handleChange(e); 
    const { name, value } = e.target;
    const age = calculateAge(e.target.value)
    setFormData({ ...formData, ["age"]: age, [name]: value });
  }

  const handleSave = async () => {
    try {
      if (formData?.is_edit) {
        // Update existing employee
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_employee_details`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData
        });
      } else {
        // Create new employee
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/create_employee`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData
        });
      }
      alert('Employee saved successfully!');
    } catch (error) {
      console.error('Error saving employee:', error);
    }
  };

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='card card-primary'>
          <div className='card-header'>
            <h4>{formData?.is_edit ? 'Edit Employee' : 'Add Employee'}</h4>
          </div>
          <div className='card-body p-2'>
            <div className='form-group'>
              <label>Full Name</label>
              <input
                type='text'
                name='full_name'
                className='form-control'
                placeholder='Enter Full Name'
                value={formData.full_name}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Known As</label>
              <input
                type='text'
                name='known_as'
                className='form-control'
                placeholder='Enter Known As'
                value={formData.known_as}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Role</label>
              <input
                type='text'
                name='role'
                className='form-control'
                placeholder='Enter Role'
                value={formData.role}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Email ID</label>
              <input
                type='text'
                name='email_id'
                className='form-control'
                placeholder='Enter Email ID'
                value={formData.email_id}
                onChange={handleChange}
              />
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label>Phone Code</label>
                  <input
                    type='text'
                    name='phone_code'
                    className='form-control'
                    placeholder='Enter Phone Code'
                    value={formData.phone_code}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-md-8'>
                <div className='form-group'>
                  <label>Phone Number</label>
                  <input
                    type='text'
                    name='phone_number'
                    className='form-control'
                    placeholder='Enter Phone Number'
                    value={formData.phone_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <div className='form-group'>
                  <label>Birth Date</label>
                  <input
                    type='date'
                    name='dob'
                    className='form-control'
                    placeholder='Enter Birth Date'
                    defaultValue={formData.dob.split("T")[0]}
                    // value={formData.dob}
                    onChange={handleDob}
                  />
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label>Age</label>
                  <input
                    type='number'
                    name='age'
                    className='form-control'
                    placeholder='Enter Age'
                    value={formData.age}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label>Gender</label>
              <select
                name='gender'
                className='form-select'
                defaultValue={formData.gender}
                onChange={handleChange}
              >
                <option value={'MALE'} selected>Male</option>
                <option value={'FEMALE'}>Female</option>
                <option value={'OTHER'}>Other</option>
              </select>
            </div>
            <div className='form-group'>
              <label>Religion</label>
              <input
                type='text'
                name='religion'
                className='form-control'
                placeholder='Enter Religion'
                value={formData.religion}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Nationality</label>
              <input
                type='text'
                name='nationality'
                className='form-control'
                placeholder='Enter Nationality'
                value={formData.nationality}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <button
                className='btn btn-success text-white'
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
