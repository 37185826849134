import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ReactSession from "../../../components/ReactSession";

export default function EmployeeHub() {
  ReactSession.setStoreType("localStorage");
  const [search , setsearch] = useState('')
  return (
    <div class="card" style={{height: '48%'}}>
          <div class="card-body content2">
          <h6 className="text-dark">Employee hub</h6>
          <div className="row">
            <div className="col-10">
                <input type="text" className='form-control' placeholder='Name, job title' onChange={e=> setsearch(e.target.value)}/>
            </div>
            <div className="col-2">
                <Link  className='btn btn-danger' to={`/Architecture/Hr/Employees/Employee?s=${search}`}><i className='bi bi-search'></i></Link>
            </div>
          </div>

          </div>
          <div class="card-body d-flex justify-content-between">
            <a href="#asdasd" class="card-link">See all employees</a>
          </div>
        </div>
  )
}
