import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { calculateAge } from '../../utils/DateFunctions'
import ReactSession from "../../components/ReactSession";

export default function CreateLocation() {
    ReactSession.setStoreType("localStorage");
    const { timeoffinlieu_id } = useParams();
    const [formData, setFormData] = useState({
        server_id: ReactSession.get('server_id'),
        is_edit: false,
        timeoffinlieu_id: timeoffinlieu_id,
        employee_id: '',
        start_date: new Date().toJSON().slice(0, 10),
        start_time: '',
        end_date: '',
        end_time: '',
        notes: '',
        coordinates: '',
        entry_datetime: '',
        is_edited: '',
        edited_on_datetime: '',
        edited_by_user_id: '',
    });
    const [EmployeeData, setEmployeeData] = useState([]);

    useEffect(() => {
        if (timeoffinlieu_id) {
            fetchClockIns();
        }
        fetchEmployeeDetails();
    }, [timeoffinlieu_id]);
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            server_id: ReactSession.get('server_id'),
        }
    };
    const fetchEmployeeDetails = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_employee_details`, axiosConfig);
            console.log("fetchEmployeeDetails:", response?.data?.data)
            setEmployeeData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };
    const fetchClockIns = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_clockins/fetch_single`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    server_id: ReactSession.get('server_id'),
                    id: timeoffinlieu_id,
                }
            });
            const { employee_id, start_date, start_time, end_date, end_time, notes } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { employee_id: '', start_date: '', start_time: '', end_date: '', end_time: '', notes: '' };
            setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, employee_id: employee_id, start_date: start_date, start_time: start_time, end_date: end_date, end_time: end_time, notes: notes });
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = async () => {
        try {
            if (formData?.is_edit) {
                // Update existing employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_clockins/update`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            } else {
                // Create new employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_clockins/create`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            }
            alert('Clocked in successfully created!');
        } catch (error) {
            alert('Failed to save data');
            console.error('Error saving Clocked in data:', error);
        }
    };
    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card card-primary'>
                    <div className='card-header'>
                        <h4>{formData?.is_edit ? 'Update HR Documentation Appointment' : 'Clock-ins'}</h4>
                    </div>
                    <div className='card-body p-2'>

                        <div>
                            <div className='row mt-2 mb-2'>
                                <div className='col-12'>
                                    <label>select Employee</label>
                                    {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                                    <select className='form-select' id='employee_id' name='employee_id' onChange={handleChange} defaultValue={formData?.employee_id}
                                    >
                                        <option value={''}> - SELECT - </option>
                                        {
                                            EmployeeData && EmployeeData?.length > 0 ?
                                                EmployeeData.map((val, index) => (
                                                    <option value={val.id}>{val.full_name} ({val.email_id})</option>
                                                ))
                                                : ''
                                        }
                                    </select>
                                </div>
                                <div className='col-6'>
                                    <label>Clock In Date </label>
                                    <input type="date"
                                        className='form-control' id="start_date" name='start_date' onChange={handleChange} defaultValue={formData?.start_date}
                                    />
                                </div>
                                <div className='col-6'>
                                    <label>Clock In Time</label>
                                    <input type="time"
                                        className='form-control' id="start_time" name='start_time' onChange={handleChange} defaultValue={formData?.start_time}
                                    />
                                </div>
                            </div>
                            <div className='row mt-2 mb-2'>
                                <label>Clocked Out Date And Time ( If avialable / Or leave both blank)</label>
                                <div className='col-6'>
                                    <input type="date"
                                        className='form-control' id="end_date" name='end_date' onChange={handleChange} defaultValue={formData?.end_date}
                                    />
                                </div>
                                <div className='col-6'>
                                    <input type="time"
                                        className='form-control' id="end_time" name='end_time' onChange={handleChange} defaultValue={formData?.end_time}
                                    />
                                </div>
                                <div className='col-12'>
                                    <label>Notes</label>
                                    <input type='text' className='form-control' id="notes" name='notes' onChange={handleChange} defaultValue={formData?.notes} />
                                </div>
                            </div>



                        </div>
                        <div className='form-group'>
                            <button
                                className='btn btn-success text-white'
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
