import React, { useState, useEffect } from "react";
import "../../../../Firebase";
import { getFirestore, addDoc, collection, getDocs } from "firebase/firestore";
import { CSVLink, CSVDownload } from "react-csv";
function ProfileDetails() {
  const db = getFirestore();

  const [admin, setadmin] = useState({
    username: "",
    server_name: "",
    server_id: "",
  });

  const [emp, setemp] = useState([]);


  const convertToCSV = (data) => {
    const csv = data.map(row => Object.values(row).join(',')).join('\n');
    return csv;
  };
  
  // Function to initiate the download
  const downloadCSV = (csvData, filename) => {
    const csvBlob = new Blob([csvData], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Sample data
  const sampleData = [
    { name: 'John', age: 30, city: 'New York' },
    { name: 'Jane', age: 25, city: 'Los Angeles' },
    { name: 'Doe', age: 35, city: 'Chicago' }
  ];

  const [userdata,setuserdata] = useState([])
  

  const fetchDataFromFirestore = async () => {
try{

  if(
    JSON.parse(
      localStorage.getItem("__react_session__")
    ).username !== null || JSON.parse(localStorage.getItem("__react_session__")).server_id !== null
  ){
    
    const subColRef = await collection(
      db,
      "servers",
      JSON.parse(localStorage.getItem("__react_session__")).server_id,
      "employees"
    );

    const qSnap = await getDocs(subColRef);
    // console.log(qSnap)
    // const res = await getDocs(subColRef);
    // console.log(qSnap.docs.map(d => ({id: d.id, ...d.data()})))
    const newArr = qSnap.docs
      .map((d) => ({ id: d.id, ...d.data() }))
      .filter((data, i) => {
        return (data.adminID = JSON.parse(
          localStorage.getItem("__react_session__")
        ).username);
      });
    console.log(newArr);
    setemp(newArr);
    // setdata(newArr)
    console.log(qSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
  // };
  }else{
    window.location.href = '/SignUp'
  }
}
catch(err){
  console.log(err)
}

  };
  
  const handleCSV = (id) => {
    console.log(id);
  };

  const handleDownload = () => {
    const csvData = convertToCSV(sampleData);
    downloadCSV(csvData, 'data.csv');
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("__react_session__"));
    setadmin({
      ...admin,
      username: user.username,
      server_name: user.server_name,
      server_id: user.server_id,
    });
    console.log(user);

    // fetchEmp()
    fetchDataFromFirestore();
  }, []);

  return (
    <div className="reports-sec">
      <h3>Employee details report</h3>
      <hr style={{ width: "100%", borderTop: "1px solid rgba(0,0,0,0.5)" }} />
      <div>
        <input
          placeholder="Search Employee"
          style={{
            border: "1px solid rgba(0,0,0,0.3)",
            padding: "0.5rem",
            width: "100%",
          }}
        />
      </div>

      <div style={{ marginTop: "1rem" }}>
        <div className="cards-emp">
          {emp && emp.length > 0 ? (
            emp.map((data, i) => {
              return (
                <>
                  <div className="card-p">
                    <div>
                      {data.profilePicture ? (
                        <img
                          src={data.profilePicture}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <i className="fas fa-user"></i>
                      )}
                    </div>
                    <div>
                      <h3>{data.fullName}</h3>
                      <span>Company Director</span>
                      {/* <button onClick={()=>{handleCSV(data.id)}} style={{padding:'0.3rem',backgroundColor:'#E64976',color:'white',fontSize:'0.8rem',letterSpacing:'0.02rem'}} >Generate CSV</button> */}
                      {/* <CSVLink
                        data={data}
                        filename={"my-file.csv"}
                       
                        target="_blank"
                        style={{padding:'0.3rem',backgroundColor:'#E64976',color:'white',fontSize:'0.8rem',letterSpacing:'0.02rem'}}
                      >
                        Download me
                      </CSVLink> */}

                      <button style={{padding:'0.3rem',backgroundColor:'#E64976',color:'white',fontSize:'0.8rem',letterSpacing:'0.02rem'}}  onClick={()=>handleDownload(data.id)}>Download CSV</button>
                      
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <h4>No data found</h4>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;
