import React from "react";
import ReactSession from "../../../components/ReactSession";

function Permission() {
  ReactSession.setStoreType("localStorage");
  return (
    <div className="w-100 mt-3">
      <h4>Permission</h4>
      <p className="mt-4 text-dark">Assign or revoke permission for your employees</p>
      <div className="row">
          
        <div className="col-sm-4 col-lg-3 mt-2">
          <div className="p-1 bg-light shadow ">
            <div className="input-group">
              <div className="input-group-prepend">
                <button id="button-addon2" type="submit" className="btn btn-link text-dark"><i className="fa fa-search"></i></button>
              </div>
              <input type="search" placeholder="Name, job title" aria-describedby="button-addon2" className="form-control border-0 bg-light"/>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "0.9rem" }}>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                User
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Roles
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Direct reports
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Permissions
              </th>
            </tr>
          </thead>
          <tbody className="history" style={{ fontSize: "0.9rem" }}>
            <tr>
              <td className="text-primary" style={{ fontWeight: "500" }}>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="d-flex justify-content-center align-items-center col-4 bg-primary"
                    style={{
                      width: "65px",
                      height: "65px",
                      borderRadius: "50%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      TD
                    </p>
                  </div>
                  <div className="col-8">
                    <p
                      className="text-primary"
                      style={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      Todd
                    </p>
                    <span>Care Assistant</span>
                  </div>
                </div>
              </td>
              <td className="text-dark">
                <select className="form-control w-75" name="" id="">
                  <option value="Admin">Admin</option>
                  <option value="Manager">Manager</option>
                  <option value="Employee" selected>
                    Employee
                  </option>
                </select>
              </td>
              <td className="text-dark">Everyone</td>
              <td className="text-dark">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="payroll-user[]"
                    id="payroll-user-1"
                  />
                  <label className="form-check-label" htmlFor="payroll-user-1">
                    Payroll
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-primary" style={{ fontWeight: "500" }}>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="d-flex justify-content-center align-items-center col-4 bg-primary"
                    style={{
                      width: "65px",
                      height: "65px",
                      borderRadius: "50%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      DC
                    </p>
                  </div>
                  <div className="col-8">
                    <p
                      className="text-primary"
                      style={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      Deborah Casey
                    </p>
                    <span>Trainer</span>
                  </div>
                </div>
              </td>
              <td className="text-dark">
                <select className="form-control w-75" name="" id="">
                  <option value="Admin" selected>Admin</option>
                  <option value="Manager">Manager</option>
                  <option value="Employee">
                    Employee
                  </option>
                </select>
              </td>
              <td className="text-dark"></td>
              <td className="text-dark">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="payroll-user[]"
                    id="payroll-user-1"
                    checked
                  />
                  <label className="form-check-label" htmlFor="payroll-user-1">
                    Payroll
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-primary" style={{ fontWeight: "500" }}>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="d-flex justify-content-center align-items-center col-4 bg-primary"
                    style={{
                      width: "65px",
                      height: "65px",
                      borderRadius: "50%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      JW
                    </p>
                  </div>
                  <div className="col-8">
                    <p
                      className="text-primary"
                      style={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      Jacqueline White
                    </p>
                    <span>Group Operations Director</span>
                  </div>
                </div>
              </td>
              <td className="text-dark">
                <select className="form-control w-75" name="" id="">
                  <option value="Admin">Admin</option>
                  <option value="Manager" selected>Manager</option>
                  <option value="Employee">
                    Employee
                  </option>
                </select>
              </td>
              <td className="text-dark">Everyone</td>
              <td className="text-dark">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="payroll-user[]"
                    id="payroll-user-1"
                  />
                  <label className="form-check-label" htmlFor="payroll-user-1">
                    Payroll
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Permission;
