import moment from "moment";
import React from "react";
import "./Dashboard.css";
import EmployeeHub from "./components/EmployeeHub";
import Documents from "./components/Documents";
import PropleOverview from "./components/PropleOverview";
import ReactSession from "../../components/ReactSession";
export default function Dashboard() {
  ReactSession.setStoreType("localStorage");
  return (
    <div className="row" id="Home">
      <span>
        <b>Hi Jack</b> {moment().format("ddd Do MMM YYYY")}
      </span>
      <div className="col-md-8 p-2">
      <PropleOverview/>
      </div>
      <div className="col-md-4 p-2">
        <div className="saction">
        <div className="blue-box p-2">
          <h4 className="mt-3">My summary</h4>
            <ul className="nav nav-underline gap-0">
              <li className="nav-item w-50">
                <button class="nav-link active text-dark w-100" id="absence-tab" data-bs-toggle="tab" data-bs-target="#absence-tab-pane" type="button" role="tab" aria-controls="absence-tab-pane" aria-selected="true">Absence</button>
              </li>
              <li className="nav-item w-50">
                <button class="nav-link text-dark w-100" id="overtime-tab" data-bs-toggle="tab" data-bs-target="#overtime-tab-pane" type="button" role="tab" aria-controls="overtime-tab-pane" aria-selected="false">Overtime</button>
              </li>
            </ul>
          </div>
            <div className="container-fluid">
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="absence-tab-pane" role="tabpanel" aria-labelledby="absence-tab" tabindex="0">
                    <button className="btn btn-p w-100 m-1 mt-3">Add time off</button>
                    <button className="btn btn-primary w-100 m-1">Absence history</button>
                    <div className="row align-items-center mt-3">
                      <div className="col-md-3">
                          <div class="progress blue">
                            <span class="progress-left">
                                <span class="progress-bar"></span>
                            </span>
                            <span class="progress-right">
                                <span class="progress-bar"></span>
                            </span>
                            <div class="progress-value">60%</div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <p><b>21 Days</b> Remaining:</p>
                        <p><b>28 Days</b> Allowance:</p>
                      </div>
                    </div>
                    <p>Next up - Public holiday</p>
                    <p><b className="text-primary">Mon 26 Aug 2024</b></p>
                    <div className="d-flex ">
                      <button className="btn btn-primary">Lateness</button>
                      <button className="btn btn-primary ms-2">Sickness</button>
                    </div>
                </div>
                <div class="tab-pane fade" id="overtime-tab-pane" role="tabpanel" aria-labelledby="overtime-tab" tabindex="0">...</div>
              </div>
            </div>
        </div>
      </div>
      
      <div className="col-md-4 p-2">
        <Documents/>
      </div>
      
      <div className="col-md-4 p-2">
        <EmployeeHub />
        <div class="card mt-3" style={{height: '48% '}}>
          <div class="card-body content2">
            <h6 className="text-dark">Calendar</h6>
            <p>Absences, public holidays & notable dates.</p>
            <h5 className="m-auto text-center h-100 text-dark" style={{alignContent: 'center'}}>{moment().format('LL')}</h5>
          </div>
          <div class="card-body d-flex justify-content-between">
            <a href="/Architecture/Hr/Calender" class="card-link">View Calendar</a>
          </div>
        </div>
      </div>

      <div className="col-md-4 p-2">
        <div className="card h-100">
          <div className="card-header" style={{height:'auto'}}>
            <div className="d-flex w-100 p-3 justify-content-between align-items-center">
              <h6 className="text-dark">Rotas and shifts</h6>
              <div class="btn-group" role="group" aria-label=" radio toggle button group">
                <input type="radio" className="btn-check" name="vbtn-radio" id="vbtn-radio1" autocomplete="off" checked />
                <label className="btn btn-outline-primary" for="vbtn-radio1">Me</label>
                <input type="radio" className="btn-check" name="vbtn-radio" id="vbtn-radio2" autocomplete="off" />
                <label className="btn btn-outline-primary" for="vbtn-radio2">Team</label>
              </div>
            </div>
          </div>
          <div className="card-body content">
            <div className="h-50">
                <h6 className="text-dark">Shifts</h6>
                <div className="sr-box">
                    <div className="empty-box">
                      <img src="https://cdn-icons-png.flaticon.com/512/11827/11827168.png" alt="shifts" width="120" height="120"/>
                      <p>No upcoming shifts in the next 7 days</p>
                    </div>
                </div>
            </div>
            <div className="h-50">
                <h6 className="text-dark">Rotas</h6>
                <div className="sr-box">
                    <div className="empty-box">
                      <img src="https://www.dspim.com/invest/images/investx/icons/empty-img.svg" alt="shifts" width="120" height="120"/>
                      <p>No upcoming rotas in the next 7 days</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
