import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactSession from "../../../../components/ReactSession";

export default function Employement() {
  ReactSession.setStoreType("localStorage");
  const { employee_id } = useParams();
  const modalOpen = React.useRef(null);
  const modalClose = React.useRef(null);

  const [contract, setContract] = useState([])
  const [bankDetails, setBankDetails] = useState([])
  const [roleInfo, setRoleInfo] = useState([])
  const [salary, setSalary] = useState([])
  const [sensitiveInfo, setSensitiveInfo] = useState([])

  const [formData, setFormData] = useState([])

  useEffect(() => {
    fetchEmployement()
  }, [])
  const fetchEmployement = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_employement`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          server_id: ReactSession.get('server_id'),
          employee_id: employee_id
        }
      });
      setContract([])
      setBankDetails([])
      setRoleInfo([])
      setSalary([])
      setSensitiveInfo([])
      if (res?.data && res.data?.data) {
        if (res?.data?.data?.employee_contract_summary) {
          setContract(res.data.data.employee_contract_summary)
        }
        if (res?.data?.data?.employee_bank_details) {
          setBankDetails(res.data.data.employee_bank_details)
        }
        if (res?.data?.data?.employee_role_info) {
          setRoleInfo(res.data.data.employee_role_info)
        }
        if (res?.data?.data?.employee_salary) {
          setSalary(res.data.data.employee_salary)
        }
        if (res?.data?.data?.employee_sensitive_info) {
          setSensitiveInfo({
            ...res.data.data.employee_sensitive_info,
            passport_expiry_date: moment(res.data.data.employee_sensitive_info.passport_expiry_date).format('YYYY-MM-DD'),
            follow_up_date: moment(res.data.data.employee_sensitive_info.follow_up_date).format('YYYY-MM-DD'),
            visa_expiry_date: moment(res.data.data.employee_sensitive_info.visa_expiry_date).format('YYYY-MM-DD'),
            driving_licence_expiry_date: moment(res.data.data.employee_sensitive_info.driving_licence_expiry_date).format('YYYY-MM-DD'),
          })
        }
      }
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  }
  const handleModal = (modalName, data) => {
    if (modalName === 'employment_summary' || modalName === 'contract_summary') {
      setFormData({
        edit: modalName, ...data,
        contract_start_date: moment(data.contract_start_date).format('YYYY-MM-DD'),
        employee_start_date: moment(data.employee_start_date).format('YYYY-MM-DD'),
        annual_leave_year_start: moment(data.annual_leave_year_start).format('YYYY-MM-DD'),
      })
    } else {
      setFormData({ edit: modalName, ...data })

    }
    modalOpen.current?.click()
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const handleChangeBank = (e) => {
    const { name, value } = e.target
    setBankDetails({ ...bankDetails, [name]: value })
  }
  const handleChangeSensitive = (e) => {
    const { name, value } = e.target
    setSensitiveInfo({ ...sensitiveInfo, [name]: value })
  }
  const handleChangeBankChecked = (e) => {
    const { name } = e.target;
    const isValue = bankDetails[name] === 0 ? 1 : 0
    setBankDetails({ ...bankDetails, [name]: isValue });
  };
  const calculateTotalHrs = () => {
    const daysOfWeek = {
      "Monday": 0,
      "Tuesday": 1,
      "Wednesday": 2,
      "Thursday": 3,
      "Friday": 4,
      "Saturday": 5,
      "Sunday": 6
    };
    const start = moment(formData?.working_time_pattern_from_time, 'HH:mm:ss');
    const end = moment(formData?.working_time_pattern_to_time, 'HH:mm:ss');

    // Calculate the duration of a single workday in hours
    const workdayDuration = moment.duration(end.diff(start));
    const workdayHours = workdayDuration.asHours();

    // Define the number of workdays in a week (Monday to Friday)

    const firstDayNumber = daysOfWeek[formData?.working_time_pattern_from_day];
    const secondDayNumber = daysOfWeek[formData?.working_time_pattern_to_day];

    // Calculate the difference in days
    const totalDays = (secondDayNumber - firstDayNumber) + 1;

    const workdaysPerWeek = totalDays >= 0 ? totalDays : totalDays + 7;;

    // Calculate the total work hours in a week
    const totalWorkHours = workdayHours * workdaysPerWeek;
    setFormData({ ...formData, contract_hours_per_week: totalWorkHours ? totalWorkHours : 0 })
  }
  const handleUpdate = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_initial_information`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {...formData, server_id: ReactSession.get('server_id')}
      });
      fetchEmployement()
      modalClose.current?.click()
      setFormData({ edit: 'Loading' })

    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  }
  const handleBankInfo = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_bank_information`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {...bankDetails, server_id: ReactSession.get('server_id'),}
      });
      if (res.data.res > 0) {
        toast.success('Bank information saved')
      }
      else {
        toast.danger('failed to save data!')
      }
      fetchEmployement()
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  }
  const handleSensitiveInfo = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_sensitiveInfo_information`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {...sensitiveInfo, server_id: ReactSession.get('server_id'),}
      });
      if (res.data.res > 0) {
        toast.success('Information saved!')
      }
      else {
        toast.danger('failed to save data!')
      }
      fetchEmployement()
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  }
  return (
    <div className='row'>
      <div className="accordion col-6" id="accordionExample">
        <div className="accordion-item my-2">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <div className='w-100'>
                <h6 className='text-dark fw-bold'>Contract and annual leave information</h6>
                <p className='text-size-p cfw-Normal '>Contract hours of works, employement start date and leave entitlement</p>
              </div>
            </div>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div className="accordion-body ">
              <div className="row">
                <p className='col-5 my-2'>Entitlement unit in </p>
                <p className='col-7 my-2'>Days</p>
              </div>
              <div className="row">
                <p className='col-5 my-2'>Annual leave balance</p>
                <p className='col-5 my-2'>28 Days</p>
                <button className='col-2 text-primary'><i className='bi bi-pen'></i> Edit</button>
              </div>
              <div className="row mt-3">
                <h6 className='col-10 text-dark'>Contract summary</h6>
                <button className='col-2 text-primary' onClick={() => handleModal('contract_summary', contract)}><i className='bi bi-pen'></i> Edit</button>
                <p className='col-5 my-2'>Entitlement type</p>
                <p className='col-7 my-2'>{contract.entitlement_type}</p>
                <p className='col-5 my-2'>Entitlement Unit</p>
                <p className='col-7 my-2'>{contract.entitlement_unit}</p>
                <p className='col-5 my-2'>Contract start date</p>
                <p className='col-7 my-2'>{moment(contract?.contract_start_date).format('LL')}</p>
                <p className='col-5 my-2'>Working type patterns</p>
                <p className='col-7 my-2'>{moment(contract.working_time_pattern_from_day, 'dddd').format('ddd')}-{moment(contract.working_time_pattern_to_day, 'dddd').format('ddd')} {moment(contract.working_time_pattern_from_time, 'HH:mm').format('h a')} - {moment(contract.working_time_pattern_to_time, 'HH:mm').format('h a')}</p>
                <p className='col-5 my-2'>Contract hours per week</p>
                <p className='col-7 my-2'>{contract.contract_hours_per_week} hrs</p>
                <p className='col-5 my-2'>Annual leave year start</p>
                <p className='col-7 my-2'>{moment(contract.annual_leave_year_start).format('DD MMM')}</p>
                <p className='col-5 my-2'>Min. leave entitlement</p>
                <p className='col-7 my-2'>{contract.min_leave_entitlement} Days</p>


                <h6 className='col-12 text-dark'>Place of work</h6>
                <span className='col-5 my-2 fw-light'><p className='text-dark fs-6'>Working location</p>Determines public holidays leave types</span>
                {contract.working_location > 0 ?
                  <span className='col-7 my-2 fw-light'>
                    <p className='text-dark fs-6'>{contract.address_1}</p>
                    {contract.address_2}
                    <br />
                    {contract.city}
                    <br />
                    {contract.state}
                    <br />
                    {contract.post_code} <br /> {contract.country} </span>
                  :
                  <span className='col-7 my-2 fw-light'>On site</span>
                }

                <span className='col-5 my-3 fw-light'><p className='text-dark fs-6'>Public holidays for</p>Deducated from entitlement</span>
                {/* <p className='col-7 my-2'>England & wales</p> */}

              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item my-2">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <div className='w-100'>
                <h6 className='text-dark fw-bold'>Employment summary</h6>
                <p className='text-size-p cfw-Normal '>Start date and length of service</p>
              </div>
            </div>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="row">
                <button className='col-12 text-end text-primary' onClick={() => handleModal('employment_summary', contract)}><i className='bi bi-pen'></i> Edit</button>
                <p className='col-5 my-2'>Employee start date</p>
                <p className='col-7 my-2'>{moment(contract?.employee_start_date).format('LL')}</p>
                <p className='col-5 my-2'>Length of service</p>
                <p className='col-7 my-2'>{contract?.length_of_service}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion col-6" id="accordionExample">
        <div className="accordion-item my-2">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2One" aria-expanded="true" aria-controls="collapse2One">
              <div className='w-100'>
                <h6 className='text-dark fw-bold'>Role information</h6>
                <p className='text-size-p cfw-Normal '>Job title, probation and notice period</p>
              </div>
            </div>
          </h2>
          <div id="collapse2One" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="row">
                <button className='col-12 text-end text-primary' onClick={() => handleModal('role_info', roleInfo)}><i className='bi bi-pen'></i> Edit</button>
                <p className='col-5 my-2'>Job title</p>
                <p className='col-7 my-2'>{roleInfo.job_title}</p>
                <p className='col-5 my-2'>Contract type</p>
                <p className='col-7 my-2'>{roleInfo.contract_type}</p>

                <p className='col-5 my-2'>Team(s)</p>
                <p className='col-7 my-2'>Whites Traning Services</p>

                <p className='col-5 my-2'>Reports to</p>
                <p className='col-7 my-2'>{roleInfo.reports_to}</p>

                <p className='col-5 my-2'>Probation required</p>
                <p className='col-7 my-2'>{roleInfo.probation_required > 0 ? 'Yes' : 'No'}</p>

                <p className='col-5 my-2'>Notice period</p>
                <p className='col-7 my-2'>{roleInfo.notice_period > 0 ? roleInfo.notice_period : 'Not specified'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item my-2">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2Two" aria-expanded="false" aria-controls="collapse2Two">
              <div className='w-100'>
                <h6 className='text-dark fw-bold'>Salary information</h6>
                <p className='text-size-p cfw-Normal '>Salary amount, rate and payment frequency</p>
              </div>
            </div>
          </h2>
          <div id="collapse2Two" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="row">
                <button className='col-12 text-end text-primary' onClick={() => handleModal('salary', salary)}><i className='bi bi-pen'></i> Edit</button>
                <p className='col-5 my-2'>Amount/rate</p>
                <p className='col-7 my-2'>{salary.salary_ammount ? salary.salary_ammount + ' per year' : 'Not specified'}</p>

                <p className='col-5 my-2'>Hourly rate</p>
                <p className='col-7 my-2'>{salary.hourly_rate ? salary.hourly_rate : 'Not specified'}</p>

                <p className='col-5 my-2'>Payment frequency</p>
                <p className='col-7 my-2'>{salary.payment_frequency ? salary.payment_frequency : 'Not specified'}</p>

                <p className='col-5 my-2'>Efective date</p>
                <p className='col-7 my-2'>{salary.efective_date ? salary.efective_date : 'Not specified'}</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item my-2">
            <h2 className="accordion-header">
              <div className="accordion-button custom-accordian collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <div className='w-100'>
                  <h6 className='text-dark fw-bold'>Payroll information</h6>
                  <p className='text-size-p cfw-Normal '>Payroll number and pension details</p>
                </div>
              </div>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <h6 className='text-center'><i className='bi bi-sun'></i> Nothing to se here.</h6>
              </div>
            </div>
          </div> */}

        <div className="accordion-item my-2">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              <div className='w-100'>
                <h6 className='text-dark fw-bold'>Bank information</h6>
                <p className='text-size-p cfw-Normal '>Employee bank details</p>
              </div>
            </div>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="row">
                <p className='col-5 my-2'>Name on account</p>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='account_name' name='account_name' onChange={handleChangeBank} value={bankDetails?.account_name} placeholder='Account Name' />
                  <label htmlFor="account_name">Account name. Max 60 chars.</label>
                </div>

                <div className='col-5 my-2'>Name of bank</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='bank_name' name='bank_name' onChange={handleChangeBank} value={bankDetails?.bank_name} placeholder='Bank Name' />
                  <label htmlFor="bank_name">Bank name. Max 60 chars</label>
                </div>

                <div className='col-5 my-2'>Bank branch</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='bank_branch' name='bank_branch' onChange={handleChangeBank} value={bankDetails?.bank_branch} placeholder='Bank branch' />
                  <label htmlFor="bank_branch">Bank branch location</label>
                </div>

                <div className='col-5 my-2'>Account number</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='account_number' name='account_number' onChange={handleChangeBank} value={bankDetails?.account_number} placeholder='12345678' />
                  <label htmlFor="account_number">Account number of the bank</label>
                </div>

                <div className='col-5 my-2'>Sort code</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='sort_code' name='sort_code' onChange={handleChangeBank} value={bankDetails?.sort_code} placeholder='00-00-00' />
                  <label htmlFor="sort_code">E.g. 00-00-00</label>
                </div>
                <div className="col-12 mt-2">
                  <button className='btn btn-p my-0' onClick={handleBankInfo}>Save bank details</button>
                  <button className='btn btn-primary ms-3'>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item my-2">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian colladivsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              <div className='w-100'>
                <h6 className='text-dark fw-bold'>Sensitive information</h6>
                <p className='text-size-p cfw-Normal '>Tax, NL and eligibility</p>
              </div>
            </div>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="row">
                <h6 className='col-12 text-dark'>Tax</h6>
                <p className='col-5 my-2'>Tax code</p>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='tax_code' name='tax_code' onChange={handleChangeSensitive} value={sensitiveInfo.tax_code} placeholder='Tax code here' />
                </div>

                <h6 className='col-12 text-dark'>National insurance</h6>
                <div className='col-5 my-2'>Nil number</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='nil_number' name='nil_number' onChange={handleChangeSensitive} value={sensitiveInfo.nil_number} placeholder='Nil number ' />
                </div>

                <h6 className='col-12 text-dark'>Passport</h6>
                <div className='col-5 my-2'>Passport number</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='passport_number' name='passport_number' onChange={handleChangeSensitive} value={sensitiveInfo.passport_number} placeholder='Passport number' />
                </div>

                <div className='col-5 my-2'>Country of issue</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='passport_country' name='passport_country' onChange={handleChangeSensitive} value={sensitiveInfo.passport_country} placeholder='Passport country' />
                </div>

                <div className='col-5 my-2'>Date of expiry</div>
                <div className='col-7 my-2'>
                  <input type="date" className="form-control" id='passport_expiry_date' name='passport_expiry_date' onChange={handleChangeSensitive} value={moment(sensitiveInfo.passport_expiry_date).format('YYYY-MM-DD')} />
                </div>

                <h6 className='col-12 text-dark'>Driving licence</h6>
                <div className='col-5 my-2'>Licence number</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='driver_licence_number' name='driver_licence_number' onChange={handleChangeSensitive} value={sensitiveInfo.driver_licence_number} placeholder='Your driving licence number' />
                </div>

                <div className='col-5 my-2'>Country of issue</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='driving_licence_country' name='driving_licence_country' onChange={handleChangeSensitive} value={sensitiveInfo.driving_licence_country} placeholder='Choose your country' />
                </div>

                <div className='col-5 my-2'>Licence class</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='driving_licence_class' name='driving_licence_class' onChange={handleChangeSensitive} value={sensitiveInfo.driving_licence_class} placeholder='eg. B + C' />
                </div>

                <div className='col-5 my-2'>Date of expiry</div>
                <div className='col-7 my-2'>
                  <input type="date" className="form-control" id='driving_licence_expiry_date' name='driving_licence_expiry_date' onChange={handleChangeSensitive} value={moment(sensitiveInfo.driving_licence_expiry_date).format('YYYY-MM-DD')} />
                </div>


                <h6 className='col-12 text-dark'>Visa</h6>
                <div className='col-5 my-2'>Visa number</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='visa_number' name='visa_number' onChange={handleChangeSensitive} value={sensitiveInfo.visa_number} placeholder='VISA number' />
                </div>

                <div className='col-5 my-2'>Visa expiry date</div>
                <div className='col-7 my-2'>
                  <input type="date" className="form-control" id='visa_expiry_date' name='visa_expiry_date' onChange={handleChangeSensitive} value={moment(sensitiveInfo.visa_expiry_date).format('YYYY-MM-DD')} />
                </div>

                <h6 className='col-12 text-dark'>DBS check</h6>
                <div className='col-5 my-2'>DBS initial check conducted</div>
                <div className='col-7 my-2'>
                  <input type="checkbox" className="form-check-input p-2" id='dbs_initial_check' name='dbs_initial_check' onChange={handleChangeBankChecked} checked={sensitiveInfo.dbs_initial_check ? true : false} />
                </div>

                <div className='col-5 my-2'>DBS check conducted</div>
                <div className='col-7 my-2'>
                  <input type="checkbox" className="form-check-input p-2" id='dbs_check_conducted' name='dbs_check_conducted' onChange={handleChangeBankChecked} checked={sensitiveInfo.dbs_check_conducted ? true : false} />
                </div>

                <div className='col-5 my-2'>Follow-up date</div>
                <div className='col-7 my-2'>
                  <input type="date" className="form-control" id='follow_up_date' name='follow_up_date' onChange={handleChangeSensitive} value={moment(sensitiveInfo.follow_up_date).format('YYYY-MM-DD')} />
                </div>


                <h6 className='col-12 text-dark'>Right to work</h6>
                <div className='col-5 my-2'>Right to work status</div>
                <div className='col-7 my-2'>
                  <input type="text" className="form-control" id='right_to_work_status' name='right_to_work_status' placeholder='Select status' />
                </div>


                <div className="col-12 mt-2">
                  <button className='btn btn-p my-0' onClick={handleSensitiveInfo}>Save</button>
                  <button className='btn btn-primary ms-3'>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modalEditAll" ref={modalOpen}>
        Launch demo modal
      </button>
      <div class="modal fade" id="modalEditAll" tabindex="-1" aria-labelledby="modalEditAllLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="modalEditAllLabel">Modal {formData?.edit?.toLowerCase().split('_').map((word, i) => i ? word : word[0].toUpperCase() + word.slice(1)).join(' ')}</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={modalClose}></button>
            </div>
            <div class="modal-body">
              {formData?.edit === 'contract_summary' ?
                <div className='row'>
                  <div className="col-12">
                    <label htmlFor="">Contract start date</label>
                    <input type="date" name='contract_start_date' onChange={handleChange} value={moment(formData?.contract_start_date).format('YYYY-MM-DD')} className='form-control' />
                  </div>
                  <label htmlFor="" className='mt-2'>Working Time patterns</label>
                  <div className="col-5">
                    <select name="working_time_pattern_from_day" id="working_time_pattern_from_day" value={formData?.working_time_pattern_from_day} onChange={handleChange} className='form-select'>
                      <option value="">- Select Day from -</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select>
                  </div>
                  <div className="col-1 m-auto"> -- </div>
                  <div className="col-5">
                    <select name="working_time_pattern_to_day" id="working_time_pattern_to_day" value={formData?.working_time_pattern_to_day} onChange={handleChange} className='form-select'>
                      <option value="">- Select Day To -</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Sunday</option>
                    </select>
                  </div>
                  <div className="col-5">
                    <input type="time" name='working_time_pattern_from_time' value={moment(formData?.working_time_pattern_from_time, 'HH:mm').format('HH:mm')} onChange={handleChange} className='form-control' />
                  </div>
                  <div className="col-1 m-auto"> -- </div>
                  <div className="col-5">
                    <input type="time" name='working_time_pattern_to_time' value={moment(formData?.working_time_pattern_to_time, 'HH:mm').format('HH:mm')} onChange={handleChange} className='form-control' />
                  </div>
                  <div className="col-12 my-2">
                    <label className="d-block" htmlFor="">Contract hours per week</label>
                    <input type="text" name='contract_hours_per_week' value={formData?.contract_hours_per_week} className='form-control w-50 d-inline-block' disabled /> <button className='ms-3 btn btn-primary d-inline-block' onClick={calculateTotalHrs}>Calculate</button>
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor="">Annual leave year start</label>
                    <select name="annual_leave_year_start" id="annual_leave_year_start" onChange={handleChange} value={moment(formData?.annual_leave_year_start).format('YYYY-MM-DD')} className="form-select">
                      <option value="">Select a annual year</option>
                      <option value={moment('01/01/' + moment().format('YYYY')).format('YYYY-MM-DD')}>January 1st</option>
                      <option value={moment('04/01/' + moment().format('YYYY')).format('YYYY-MM-DD')}>April 1st</option>
                    </select>
                  </div>
                  <div className="col-6 my-2">
                    <label htmlFor="">Min. leave entitlement</label>
                    <input type="number" name='min_leave_entitlement' onChange={handleChange} value={formData?.min_leave_entitlement} className='form-control' />
                  </div>
                  <div className="col-6 my-2">
                    <label htmlFor="">length_of_service</label>
                    <input type="text" name='length_of_service' onChange={handleChange} value={formData?.length_of_service} className='form-control' placeholder='leanth of server in year or months' />
                  </div>

                  <div className="col-12 my-2">
                    <label htmlFor="">Working location</label>
                    <select name="working_location" id="working_location" onChange={handleChange} value={formData?.working_location} className="form-select">
                      <option value="">Select a working location</option>
                      <option value={0}>On-site</option>
                      <option value={1}>Private</option>
                    </select>
                  </div>
                  {formData?.working_location > 0 ?
                    <div className="row m-0 blue-box py-2">
                      <div className="col-6"><label htmlFor="">Address 1</label><textarea name="address_1" id="address_1" onChange={handleChange} value={formData?.address_1} className='form-control'></textarea></div>
                      <div className="col-6"><label htmlFor="">Address 2</label><textarea name="address_2" id="address_2" onChange={handleChange} value={formData?.address_2} className='form-control'></textarea></div>
                      <div className="col-6"><label htmlFor="">Country</label><input type='text' name="country" id="country" onChange={handleChange} value={formData?.country} className='form-control' /></div>
                      <div className="col-6"><label htmlFor="">State</label><input type='text' name="state" id="state" onChange={handleChange} value={formData?.state} className='form-control' /></div>
                      <div className="col-6"><label htmlFor="">City</label><input type='text' name="city" id="city" onChange={handleChange} value={formData?.city} className='form-control' /></div>
                      <div className="col-6"><label htmlFor="">Post code</label><input type='text' name="post_code" id="post_code" onChange={handleChange} value={formData?.post_code} className='form-control' /></div>
                    </div>
                    : ''}
                </div>
                : formData?.edit === 'employment_summary' ?
                  <div className='row'>
                    <div className="col-12">
                      <label htmlFor="">Employee start date</label>
                      <input type="date" name='employee_start_date' onChange={handleChange} value={moment(new Date(formData?.employee_start_date).toDateString()).format('YYYY-MM-DD')} className='form-control' />
                    </div>
                    <div className="col-12 my-2">
                      <label htmlFor="">Length of service</label>
                      <input type="text" name='length_of_service' onChange={handleChange} value={formData?.length_of_service} className='form-control' placeholder='leanth of server in year or months' />
                    </div>
                  </div>
                  : formData?.edit === 'role_info' ?
                    <div className='row'>
                      <div className="col-12 my-2">
                        <label htmlFor="">Job title</label>
                        <input type="text" name='job_title' onChange={handleChange} value={formData?.job_title} className='form-control' placeholder='Job title' />
                      </div>
                      <div className="col-12 my-2">
                        <label htmlFor="">Contract type</label>
                        <select name='contract_type' onChange={handleChange} value={formData?.contract_type} className='form-select' >
                          <option value="">- Select contract type -</option>
                          <option value="Part-Time">Part-Time</option>
                          <option value="Full-Time">Full-Time</option>
                          <option value="Hybrid">Hybrid</option>
                        </select>
                      </div>
                      <div className="col-12 my-2">
                        <label htmlFor="">Reports to</label>
                        <input type="text" name='reports_to' onChange={handleChange} value={formData?.reports_to} className='form-control' placeholder='Reports to' />
                      </div>
                      <div className="col-12 my-2">
                        <label htmlFor="">Probition required</label>
                        <select name='probation_required' onChange={handleChange} value={formData?.probation_required} className='form-select' >
                          <option value="">- Select probition -</option>
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </select>
                      </div>
                      <div className="col-12 my-2">
                        <label htmlFor="">Notice period</label>
                        <input type="text" name='notice_period' onChange={handleChange} value={formData?.notice_period} className='form-control' placeholder='' />
                      </div>
                    </div>
                    : formData?.edit === 'salary' ?
                      <div className='row'>
                        <div className='row'>
                          <div className="col-12 my-2">
                            <label htmlFor="">Salary ammount</label>
                            <input type="text" name='salary_ammount' onChange={handleChange} value={formData?.salary_ammount} className='form-control' placeholder='Salary ammount' />
                          </div>
                          <div className="col-12 my-2">
                            <label htmlFor="">Hourly rate</label>
                            <input type="text" name='hourly_rate' onChange={handleChange} value={formData?.hourly_rate} className='form-control' placeholder='Hourly rate' />
                          </div>
                          <div className="col-12 my-2">
                            <label htmlFor="">Payment frequency</label>
                            <input type="text" name='payment_frequency' onChange={handleChange} value={formData?.payment_frequency} className='form-control' placeholder='Payment frequency' />
                          </div>
                          <div className="col-12 my-2">
                            <label htmlFor="">Efective date</label>
                            <input type="text" name='efective_date' onChange={handleChange} value={formData?.efective_date} className='form-control' placeholder='Efective date' />
                          </div>
                        </div>
                      </div>
                      :
                      'Choose a template'
              }
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" onClick={handleUpdate}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
