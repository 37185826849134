import React from 'react'
import EmployeeDocument from '../../../Documents/components/EmployeeDocument'
import ReactSession from "../../../../components/ReactSession";

export default function Documents() {
  ReactSession.setStoreType("localStorage");
  return (
    <EmployeeDocument />
  )
}
