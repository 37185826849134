export const CREATE_ARCHITECTUREINVOICE = "CREATE_ARCHITECTUREINVOICE";   
export const RETRIEVE_ARCHITECTUREINVOICE = "RETRIEVE_ARCHITECTUREINVOICE";   
export const RETRIEVESINGLE_ARCHITECTUREINVOICE = "RETRIEVESINGLE_ARCHITECTUREINVOICE";   
export const UPDATE_ARCHITECTUREINVOICE = "UPDATE_ARCHITECTUREINVOICE";   
export const DELETE_ARCHITECTUREINVOICE = "DELETE_ARCHITECTUREINVOICE";   

export const CREATE_ARCHITECTUREPAYSLIP = "CREATE_ARCHITECTUREPAYSLIP";   
export const RETRIEVE_ARCHITECTUREPAYSLIP = "RETRIEVE_ARCHITECTUREPAYSLIP";   
export const RETRIEVESINGLE_ARCHITECTUREPAYSLIP = "RETRIEVESINGLE_ARCHITECTUREPAYSLIP";   
export const UPDATE_ARCHITECTUREPAYSLIP = "UPDATE_ARCHITECTUREPAYSLIP";   
export const DELETE_ARCHITECTUREPAYSLIP = "DELETE_ARCHITECTUREPAYSLIP";   
