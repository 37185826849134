import React from 'react'
import ReactSession from "../../../components/ReactSession";

export default function Documents() {
  ReactSession.setStoreType("localStorage");
  return (
    <div className="card">
        <div className="card-header">
          <div className='d-block  w-100 p-3'>
            <h6 className="text-dark">Document management</h6>
              <div className="d-flex justify-content-between mmt-3">
                <p className='fs-6 fw-bold my-1 text-dark p-0 m-0'>To-do List</p>
                <p className='fs-6 fw-bold my-1  p-0 m-0'>All done</p>
              </div>
              <div className="sr-box">
                <div className="empty-box">
                  <img src="https://www.nfocus.co.uk/wp-content/uploads/2018/05/nfocus_health-check_graphic-200x200.png" width={120} height={120} alt="asd"/>
                    <p className='text-dark fs-6 mt-1'>Your to-do list is empty</p>
                </div>
              </div>
          </div>
        </div>
        <div className="card-body content">
        <h6 className="text-dark">Document folders</h6>
        <br />
        <br />
        <a href="/Architecture/Hr/Documents" className="card-link">My Documents</a>
        <br />

        <a href="/Architecture/Hr/Documents" className="card-link">Company documents</a>
        </div>
        <div className="card-body d-flex justify-content-between">
        <a href="/Architecture/Hr/Documents" className="card-link">View all</a>
        <a href="/Architecture/Hr/Appointments/Request" className="card-link">Request an appointment</a>
        </div>
    </div>
  )
}
