import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import ReactSession from "../../../components/ReactSession";

function History() {
  ReactSession.setStoreType("localStorage");
  const [search, setsearch] = useState({
    server_id: ReactSession.get('server_id'),
    filter_by_employee: '',
    filter_by_status: '',
    filter_by_start_date: '',
    filter_by_end_date: '',
  });
  const [history, setHistory] = useState([]);

  const getFilterDate = (date) => {
    if (date) {
      setsearch(prevState => ({
        ...prevState,
        filter_by_start_date: new Date(date[0]).toDateString(),
        filter_by_end_date: new Date(date[1]).toDateString(),
      }));
    } else {
      setsearch(prevState => ({
        ...prevState,
        filter_by_start_date: '',
        filter_by_end_date: '',
      }));
    }
  }
  const getEmployeeSearch = (name) => {
    setsearch(prevState => ({
      ...prevState,
      filter_by_employee: name,
    }));
  }
  const getStatusSearch = (status) => {
    setsearch(prevState => ({
      ...prevState,
      filter_by_status: status,
    }));
  }
  useEffect(() => {
    fetchWhoisIn();
  }, []);
  const fetchWhoisIn = async () => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          server_id: ReactSession.get('server_id'),
        }
      };
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_clockins/fetch`,
        axiosConfig
      );
      if (
        response?.data &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        setHistory(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };
  const handleSearch = () => {
    let filteredData = [...history];

    // Filter by full_name
    if (search.filter_by_employee.trim() !== '') {
      filteredData = filteredData.filter(item =>
        item.full_name.toLowerCase().includes(search.filter_by_employee.toLowerCase())
      );
    }

    // Filter by status
    if (search.filter_by_status !== '') {
      filteredData = filteredData.filter(item =>
        item.status === parseInt(search.filter_by_status, 10)
      );
    }

    if (search.filter_by_start_date !== '') {
      filteredData = filteredData.filter(item =>
        new Date(item.start_date) >= new Date(search.filter_by_start_date)
      );
    }

    // Filter by end_date
    if (search.filter_by_end_date !== '') {
      filteredData = filteredData.filter(item =>
        new Date(item.end_date) <= new Date(search.filter_by_end_date)
      );
    }

    return filteredData;
  };
  const filteredData = handleSearch();
  return (
    <div className="w-100 mt-3">
      <div className="row">
        <div className="col-sm-4 col-lg-2">
          <label className="form-label" htmlFor="filter_by_employee">
            Filter By Employees
          </label>
          <input
            type="text"
            id="filter_by_employee"
            className="form-control"
            placeholder="Select Employees"
            onChange={e => getEmployeeSearch(e.target.value)}
          />
        </div>
        <div className="col-sm-4 col-lg-2">
          <label className="form-label" htmlFor="filter_by_status">
            Filter By Status
          </label>
          <select
            name="filter_by_status"
            id="filter_by_status"
            className="form-control"
            onChange={e => getStatusSearch(e.target.value)}
          >
            <option value="">View All</option>
            <option value="1">Active</option>
            <option value="0">In-Active</option>
          </select>
        </div>
        <div className="col-sm-4 col-lg-4">
          <label className="form-label" htmlFor="date_range">
            Date Range
          </label>
          <div className="row">
            <div className="col-8">
              <DateRangePicker
                format="MM/dd/yyyy"
                character=" - "
                className="w-100"
                onChange={(e) => getFilterDate(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-lg-2 mt-4">
          <button className="btn btn-danger">Add historical clock-in</button>
        </div>
        <div className="col-sm-4 col-lg-2 mt-4">
          <button className="btn btn-primary">Export to CSV</button>
        </div>
      </div>

      <div className="mt-3">
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "0.9rem" }}>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  width: "70px",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              ></th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Employee
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Clock in
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Clock out
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Breaks
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Total
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Incl. breaks <input type="checkbox" name="" id="" />
              </th>
            </tr>
          </thead>
          <tbody className="history" style={{ fontSize: "0.9rem" }}>
            {history && history.length > 0
              ? filteredData.map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td
                        style={{ lignContent: "center", textAlign: "center" }}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapseOne-${index}`}
                        aria-expanded="false"
                        aria-controls={`collapseOne-${index}`}
                        className="text-danger collapsed"
                      >
                        <i className="fa fa-angle-down"></i>
                      </td>
                      <td
                        className="text-primary"
                        style={{ fontWeight: "500" }}
                      >
                        <div className="row align-items-center justify-content-center">
                          <div
                            className="d-flex justify-content-center align-items-center col-4 bg-primary"
                            style={{
                              width: "65px",
                              height: "65px",
                              borderRadius: "50%",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "1.1rem",
                                fontWeight: "500",
                                color: "white",
                              }}
                            >
                              {data.full_name.trim().split(' ').map((part, index) => index === 0 ? part.charAt(0).toUpperCase() : part.charAt(0)).join('').toUpperCase()}
                            </p>
                          </div>
                          <div className="col-8">
                            <p
                              className="text-primary"
                              style={{ fontSize: "1rem", fontWeight: "600" }}
                            >
                              {data.full_name}
                            </p>
                            <span>{data.role}</span>
                          </div>
                        </div>
                      </td>
                      <td className="text-dark">
                        {/* <p>{moment((new Date(data.start_date).getFullYear())+'-'+(new Date(data.start_date).getMonth()+1)+'-'+(new Date(data.start_date).getDate())+''+).format("LT")}</p> */}
                        <p>{moment(data.start_time, "HH:mm:ss").format("hh:mm a")}</p>
                        <p>{moment(data.start_date).format("ll")}</p>
                      </td>
                      <td className="text-dark">
                        <p>{moment(data.end_time, "HH:mm:ss").format("hh:mm a")}</p>
                        <p>{moment(data.end_date).format("ll")}</p>
                      </td>
                      <td className="text-dark">
                        <p>0hrs 00mins</p>
                        <p>0 Breaks</p>
                      </td>
                      <td className="text-dark">Ongoing</td>
                      <td className="text-dark">
                        <button className="btn">
                          <i className={`fa fa-toggle-${data.status ? 'on text-success' : 'off text-danger'}`}></i>
                        </button>
                        <button>
                          <i className="fa fa-pen"></i>
                        </button>
                        <button className="ms-2">
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    <tr
                      key={data.id}
                      id={`collapseOne-${index}`}
                      data-bs-parent="#accordionExample"
                      className="text-center accordion-collapse collapse"
                    >
                      <td></td>
                      <td colSpan={2} className="text-start ps-5">
                        <p className="text-dark fw-bolder">Location</p> <br />
                        <p className="text-dark fw-bolder">
                          Clock in: {data.start_time}
                        </p>
                        <p>{data.notes}</p>
                        <p className="fw-bold">Remote Location</p>
                      </td>
                      <td colSpan={2} className="text-start ps-5">
                        <p className="text-dark fw-bolder">Notes</p> <br />
                        <p className="text-dark fw-bolder">
                          Clock in/Out: {data.end_time}
                        </p>
                        <p>{data.notes}</p>
                      </td>
                      <td colSpan={2} className="text-start ps-5">
                        <p className="text-dark fw-bolder">Notes</p> <br />
                        <p>No breaks takes</p>
                      </td>
                    </tr>
                  </>
                );
              })
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default History;
