import React, { useEffect, useState } from 'react'
import Hrcontext from './Hrcontext'
import axios from "axios";
import '../Firebase'
import { getFirestore, addDoc, collection, getDocs } from "firebase/firestore"; 

function Hrprovider({children}) {

  const db = getFirestore();

  const [admin,setadmin]=useState({
    username:'',
    server_name:'',
    server_id:'',


  })

const fetchDataFromFirestore = async (doc) => {

const subColRef = await collection(db, "servers", admin.server_id, doc);

const qSnap = await getDocs(subColRef)
const newArr = qSnap.docs.map(d => ({id: d.id, ...d.data()})).filter((data,i)=>{
  return data.adminID = admin.username
})
console.log(newArr)
return newArr;
// setemp(newArr)
// console.log(qSnap.docs.map(d => ({id: d.id, ...d.data()})))

};

// useEffect(() => {
//   const user = JSON.parse(localStorage.getItem("__react_session__"))
//   setadmin({...admin,username:user.username,server_name:user.server_name,server_id:user.server_id})
//   console.log(user)
  
// }, []);

  return (
    <>
    <Hrcontext.Provider value={{fetchDataFromFirestore}}>
        {children}
    </Hrcontext.Provider>
    </>
  )
}

export default Hrprovider