import React from 'react'

function Reports() {
  return (
    <div className='reports-sec'>

    <h3>Reports</h3>

    <div className='rep-card'>


    <div className='card'>

    <div className='card-l'>
        <div>
        <i className="fas fa-calendar-alt"></i>
        </div>
    </div>
    <div className='card-r'>

    <h4>Absence</h4>
    <p>A breakdown of taken, pending, cancelled and upcoming absences per employee.</p>

    <button>Generate New Report</button>

    </div>

    </div>


    <div className='card'>

<div className='card-l'>
    <div>
    <i className="fas fa-user"></i>
    </div>
</div>
<div className='card-r'>

<h4>Employee Details</h4>
<p>A breakdown of all information including absence records, personal details and contract information for a specific employee.</p>

<button onClick={()=>window.location.href = `/Architecture/Hr/Reports/ExportProfile`} >Generate New Report</button>

</div>

</div>



</div>


    
</div>
  )
}

export default Reports