import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { calculateAge } from '../../../../utils/DateFunctions';
import { toast } from 'react-toastify';
import ReactSession from "../../../../components/ReactSession";

export default function Personal() {
  ReactSession.setStoreType("localStorage");
  const { employee_id } = useParams();
  const InitialData = {
    server_id: ReactSession.get('server_id'),
    accounnt_email: '',
    address_1: '',
    address_2: '',
    city: '',
    coordinates: '',
    country: '',
    county: '',
    covid_one_date: '',
    covid_two_date: '',
    covid_one_booster_date: '',
    covid_two_booster_date: '',
    covid_one_valid: '',
    covid_two_valid: '',
    covid_one_booster_valid: '',
    covid_two_booster_valid: '',
    covid_vaccinated: '',
    dob: '',
    employee_id: '',
    first_name: '',
    gender: '',
    home_phone: '',
    id: '',
    is_trash: '',
    last_name: '',
    middle_name: '',
    mobile_phone: '',
    notes: '',
    personal_email: '',
    personal_extension: '',
    post_code: '',
    state: '',
    status: '',
    title: '',
    work_phone: '',
  }
  const [isContact, setIsContact] = useState(false)
  const [isPersonalInfo, setIsPersonalInfo] = useState(false)
  const [formData, setFormData] = useState(InitialData)
  useEffect(() => {
    fetchUserDetails();
  }, [])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChangeChecked = (e) => {
    const { name } = e.target;
    const isValue = formData[name] === 0 ? 1 : 0
    setFormData({ ...formData, [name]: isValue });
  };
  const handleCopyAddress = () => {
    const textToCopy = `Address: ${formData.address_1}
Address 2: ${formData.address_2 ? formData.address_2 : ''}
city: ${formData.city ? formData.city : ''}
state: ${formData.state ? formData.state : ''}
country: ${formData.country ? formData.country : ''}
county: ${formData.county ? formData.county : ''}
post code: ${formData.post_code ? formData.post_code : ''}`

    // Using the Clipboard API to copy text
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success('Address copied to clipboard')
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  }
  const fetchUserDetails = async () => {
    try {
      const axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { 
        server_id: ReactSession.get('server_id'),
        employee_id: employee_id 
        }
      };
      const res = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_employee_full_details`, axiosConfig);
      if (res?.data && res?.data?.data) {
        setFormData(res.data.data)
      }

    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };
  const handleSave = async () => {
    try {
      // Create new employee
      await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_full_details`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData
      });
      alert('Information saved successfully!');
      setIsContact(false)
      setIsPersonalInfo(false)
    } catch (error) {
      console.error('Error saving information:', error);
    }
  };
  return (
    <div className='row'>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian" type="button" data-bs-toggle="collapse" data-bs-target="#collapseContact" aria-expanded="true" aria-controls="collapseContact">
              <div className='w-100'>
                <h5 className='text-dark fw-bold'>Contact information</h5>
              </div>
            </div>
          </h2>
          <div id="collapseContact" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div className="accordion-body ">
              <div className='col-12 text-right'>
                <button className=" text-primary fw-bold" onClick={() => setIsContact(!isContact)}>Edit</button>
              </div>
              {isContact ?
                <div className="row">
                  <label htmlFor="accounnt_email" className=' my-2 col-2'>Account email</label>
                  <div className="col-10  my-2">
                    <input type="text" name="accounnt_email" id="accounnt_email" placeholder='Account email' className="w-25 form-control" onChange={handleChange} value={formData.accounnt_email} />
                  </div>

                  <label htmlFor="personal_email" className=' my-2 col-2'>Personal email</label>
                  <div className="col-10  my-2">
                    <input type="text" name="personal_email" id="personal_email" placeholder='Personal email' className="w-25 form-control" onChange={handleChange} value={formData.personal_email} />
                  </div>

                  <label htmlFor="home_phone" className=' my-2 col-2'>Home phone</label>
                  <div className="col-10  my-2">
                    <input type="text" name="home_phone" id="home_phone" placeholder='Home phone' className="w-25 form-control" onChange={handleChange} value={formData.home_phone} />
                  </div>

                  <label htmlFor="mobile_phone" className=' my-2 col-2'>Mobile phone</label>
                  <div className="col-10  my-2">
                    <input type="text" name="mobile_phone" id="mobile_phone" placeholder='Mobile phone' className="w-25 form-control" onChange={handleChange} value={formData.mobile_phone} />
                  </div>

                  <label htmlFor="work_phone" className=' my-2 col-2'>Work phone</label>
                  <div className="col-10  my-2">
                    <input type="text" name="work_phone" id="work_phone" placeholder='Work phone' className="w-25 form-control" onChange={handleChange} value={formData.work_phone} />
                  </div>

                  <label htmlFor="personal_extension" className=' my-2 col-2'>Work Extension</label>
                  <div className="col-10  my-2">
                    <input type="text" name="personal_extension" id="personal_extension" placeholder='Work Extension' className="w-25 form-control" onChange={handleChange} value={formData.personal_extension} />
                  </div>
                  <div className="col-12">
                    <button className='btn btn-p' onClick={handleSave}>Save</button>
                    <button className='btn btn-primary ms-2'>Cancle</button>
                  </div>
                </div>
                :
                <div className="row">
                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Account email</p>
                    <span>{formData.accounnt_email ? formData.accounnt_email : 'Not specified'}</span>
                  </div>
                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>Personal email</p>
                    <span>{formData.personal_email ? formData.personal_email : 'Not specified'}</span>
                  </div>
                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Home phone</p>
                    <span>{formData.home_phone ? formData.home_phone : 'Not specified'}</span>
                  </div>
                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>Mobile phone</p>
                    <span>{formData.mobile_phone ? formData.mobile_phone : 'Not specified'}</span>
                  </div>

                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Work phone</p>
                    <span>{formData.work_phone ? formData.work_phone : 'Not specified'}</span>
                  </div>
                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>Personal extension</p>
                    <span>{formData.personal_extension ? formData.personal_extension : 'Not specified'}</span>
                  </div>

                </div>
              }
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePersonal" aria-expanded="true" aria-controls="collapsePersonal">
              <div className='w-100'>
                <h5 className='text-dark fw-bold'>Personal information</h5>
              </div>
            </div>
          </h2>
          <div id="collapsePersonal" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className='col-12 text-right'>
                <button className=" text-primary fw-bold" onClick={() => setIsPersonalInfo(!isPersonalInfo)}>Edit</button>
              </div>
              {isPersonalInfo ?
                <div className="row">
                  <label htmlFor="title" className='my-2 col-2'>Title</label>
                  <div className="col-10  my-2">
                    <input type="text" name="title" id="title" placeholder='Title' className="w-25 form-control" onChange={handleChange} value={formData.title} />
                  </div>

                  <label htmlFor="first_name" className=' my-2 col-2'>First name</label>
                  <div className="col-10  my-2">
                    <input type="text" name="first_name" id="first_name" placeholder='First name' className="w-25 form-control" onChange={handleChange} value={formData.first_name} />
                  </div>

                  <label htmlFor="middle_name" className=' my-2 col-2'>Midle name</label>
                  <div className="col-10  my-2">
                    <input type="text" name="middle_name" id="middle_name" placeholder='Midle name' className="w-25 form-control" onChange={handleChange} value={formData.middle_name} />
                  </div>

                  <label htmlFor="last_name" className=' my-2 col-2'>Last name</label>
                  <div className="col-10  my-2">
                    <input type="text" name="last_name" id="last_name" placeholder='Last name' className="w-25 form-control" onChange={handleChange} value={formData.last_name} />
                  </div>

                  <label htmlFor="dob" className=' my-2 col-2'>Date of birth</label>
                  <div className="col-10  my-2">
                    <input type="date" name="dob" id="dob" placeholder='Date of birth' className="w-25 form-control" onChange={handleChange} value={moment(formData.dob).format('YYYY-MM-DD')} />
                  </div>

                  <label htmlFor="gender" className=' my-2 col-2'>Gender</label>
                  <div className="col-10  my-2">
                    <select name="gender" id="gender" className="w-25 form-control" onChange={handleChange} value={formData.gender} >
                      <option value="">Choose your gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="TRANSGENDER">other</option>
                    </select>
                  </div>

                  <label htmlFor="country" className=' my-2 col-2'>Country</label>
                  <div className="col-10  my-2">
                    <input type="text" name="country" id="country" placeholder='Country' className="w-25 form-control" onChange={handleChange} value={formData.country} />
                  </div>

                  <label htmlFor="address_1" className=' my-2 col-2'>Address 1</label>
                  <div className="col-10  my-2">
                    <input type="text" name="address_1" id="address_1" placeholder='Address 1' className="w-25 form-control" onChange={handleChange} value={formData.address_1} />
                  </div>

                  <label htmlFor="address_2" className=' my-2 col-2'>Address 2</label>
                  <div className="col-10  my-2">
                    <input type="text" name="address_2" id="address_2" placeholder='Address 2' className="w-25 form-control" onChange={handleChange} value={formData.address_2} />
                  </div>


                  <label htmlFor="city" className=' my-2 col-2'>Town / City</label>
                  <div className="col-10  my-2">
                    <input type="text" name="city" id="city" placeholder='Town / City' className="w-25 form-control" onChange={handleChange} value={formData.city} />
                  </div>

                  <label htmlFor="state" className=' my-2 col-2'>State</label>
                  <div className="col-10  my-2">
                    <input type="text" name="state" id="state" placeholder='Address 3' className="w-25 form-control" onChange={handleChange} value={formData.state} />
                  </div>

                  <label htmlFor="county" className=' my-2 col-2'>County</label>
                  <div className="col-10  my-2">
                    <input type="text" name="county" id="county" placeholder='County' className="w-25 form-control" onChange={handleChange} value={formData.county} />
                  </div>

                  <label htmlFor="post_code" className=' my-2 col-2'>Postcode</label>
                  <div className="col-10  my-2">
                    <input type="text" name="post_code" id="post_code" placeholder='Postcode' className="w-25 form-control" onChange={handleChange} value={formData.post_code} />
                  </div>

                  <div className="col-12">
                    <button className='btn btn-p' onClick={handleSave}>Save</button>
                    <button className='btn btn-primary ms-2'>Cancle</button>
                  </div>
                </div>
                :
                <div className="row">
                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Title</p>
                    <span>{formData.title}</span>
                  </div>
                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>First name</p>
                    <span>{formData.first_name ? formData.first_name : 'Not specified'}</span>
                  </div>

                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Middle name</p>
                    <span>{formData.middle_name ? formData.middle_name : 'Not specified'}</span>
                  </div>
                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>Last name</p>
                    <span>{formData.last_name ? formData.last_name : 'Not specified'}</span>
                  </div>

                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Date of birth</p>
                    <span>{moment(formData.dob).format('DD-MM-YYYY')} ({calculateAge(formData.dob)} years old)</span>
                  </div>
                  <div className='col-9 my-2 mb-2'>
                    <p className='text-dark fw-bold mb-1'>Gender</p>
                    <span>{formData.gender ? formData.gender : 'Not specified'}</span>
                  </div>

                  <div className='col-3 my-2'>
                    <p className='text-dark fw-bold mb-1'>Address <i className=' ms-2 bi bi-copy text-primary' onClick={handleCopyAddress}></i></p>
                    <span className='d-block'>{formData.address_1}</span>
                    <span className='d-block'>{formData.address_2}</span>
                    <span className='d-block'>{formData.city}</span>
                    <span className='d-block'>{formData.state}</span>
                    <span className='d-block'>{formData.country}</span>
                    <span className='d-block'>{formData.county}</span>
                    <span className='d-block'>{formData.post_code}</span>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <div className="accordion-button custom-accordian" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMedical" aria-expanded="true" aria-controls="collapseMedical">
              <div className='w-100'>
                <h5 className='text-dark fw-bold'>Medical information</h5>
              </div>
            </div>
          </h2>
          <div id="collapseMedical" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <div className="row">
                <label htmlFor="covid_vaccinated" className='my-2 col-2'>COVID-19 vaccinated?</label>
                <div className="col-10  my-2">
                  <select name="covid_vaccinated" id="covid_vaccinated" className='form-select w-25' onChange={handleChange} value={formData.covid_vaccinated}>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </select>
                </div>
                <table className="table align-items-center">
                  <thead>
                    <tr style={{ fontSize: "0.9rem" }}>
                      <th scope="col" style={{ color: "#073a5d", backgroundColor: "#EBF7FF", borderBottom: "2px solid rgba(0,0,0,0.2)", width: '33.3%' }}>Vaccine type</th>
                      <th scope="col" style={{ color: "#073a5d", backgroundColor: "#EBF7FF", borderBottom: "2px solid rgba(0,0,0,0.2)", width: '33.3%' }}>Date received</th>
                      <th scope="col" style={{ color: "#073a5d", backgroundColor: "#EBF7FF", borderBottom: "2px solid rgba(0,0,0,0.2)", width: '33.3%' }}>Evidence of vaccination seen?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><p className='mt-2'>COVID-19 vaccine one</p></td>
                      <td><input type="date" name="covid_one_date" id="covid_one_date" className='form-control' onChange={handleChange} value={moment(formData.covid_one_date).format('YYYY-MM-DD')} /></td>
                      <td><input type="checkbox" name="covid_one_valid" id="covid_one_valid" className='form-check-input p-2 mt-2' onChange={handleChangeChecked} checked={formData.covid_one_valid ? true : false} /></td>
                    </tr>

                    <tr>
                      <td><p className='mt-2'>COVID-19 vaccine two</p></td>
                      <td><input type="date" name="covid_two_date" id="covid_two_date" className='form-control' onChange={handleChange} value={moment(formData.covid_two_date).format('YYYY-MM-DD')} /></td>
                      <td><input type="checkbox" name="covid_two_valid" id="covid_two_valid" className='form-check-input p-2 mt-2' onChange={handleChangeChecked} checked={formData.covid_two_valid ? true : false} /></td>
                    </tr>

                    <tr>
                      <td><p className='mt-2'>COVID-19 booster vaccine one</p></td>
                      <td><input type="date" name="covid_one_booster_date" id="covid_one_booster_date" className='form-control' onChange={handleChange} value={moment(formData.covid_one_booster_date).format('YYYY-MM-DD')} /></td>
                      <td><input type="checkbox" name="covid_one_booster_valid" id="covid_one_booster_valid" className='form-check-input p-2 mt-2' onChange={handleChangeChecked} checked={formData.covid_one_booster_valid ? true : false} /></td>
                    </tr>

                    <tr>
                      <td><p className='mt-2'>COVID-19 booster vaccine two</p></td>
                      <td><input type="date" name="covid_two_booster_date" id="covid_two_booster_date" className='form-control' onChange={handleChange} value={moment(formData.covid_two_booster_date).format('YYYY-MM-DD')} /></td>
                      <td><input type="checkbox" name="covid_two_booster_valid" id="covid_two_booster_valid" className='form-check-input p-2 mt-2' onChange={handleChangeChecked} checked={formData.covid_two_booster_valid ? true : false} /></td>
                    </tr>
                  </tbody>
                </table>

                <label htmlFor="employee_covid_vaccinated" className='my-2 col-2 mt-5'>Add notes</label>
                <div className="col-10  my-2">
                  <textarea name="notes" id="notes" rows="3" className='form-control w-25' onChange={handleChange} value={formData.notes}></textarea>
                </div>
                <span style={{ fontSize: '10px' }}>Notes are visible to employee</span>
                <button className='btn btn-p col-1 p-1 m-2' onClick={handleSave}>Save</button>
                <h6 className='col-12 text-dark'>VaccTrack pass</h6>
                <p className="col-3 mt-3">Full pass sent</p>
                <button className='btn btn-p col-1 p-1 m-2'>Resend</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
