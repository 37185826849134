import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import moment from 'moment';
import ReactSession from "../../components/ReactSession";


export default function ToDo() {
    const { todo_id } = useParams();
    ReactSession.setStoreType("localStorage");
    const [formData, setFormData] = useState({
        is_edit: false,
        server_id: ReactSession.get('server_id'),
        todo_id: todo_id,
        employee_id: '',
        title: '',
        description: '',
        start_date: '',
        deadline: '',
        priority: '',
        progress: '',
        server_name: ReactSession.get("server_name"),
    });
    const [EmployeeData, setEmployeeData] = useState([]);

    // const server_name = ReactSession.get("server_name");

    useEffect(() => {
        if (todo_id) {
            fetchToDo();
        }
        fetchEmployeeDetails()
    }, [todo_id]);
    const axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
            server_id: ReactSession.get('server_id')
        }
      };
      const fetchEmployeeDetails = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_employee_details`, axiosConfig);
          console.log("fetchEmployeeDetails:", response?.data?.data)
          setEmployeeData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
        } catch (error) {
          console.error('Error fetching employee details:', error);
        }
      };
    const fetchToDo = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_todos/fetch_single`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    id: todo_id,
                    server_id: ReactSession.get('server_id'),
                }
            });
            const { employee_id,title,description,start_date,deadline,progress,priority } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { employee_id: '',title: '',description: '',start_date: '',deadline: '',progress: '',priority: '' };
            setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, employee_id: employee_id,title: title,description: description,start_date: moment(start_date).format('YYYY-MM-DD'),deadline: deadline,progress: progress,priority: priority });
        } catch (error) {
            console.error('Error fetching todos details:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSave = async () => {
        try {
            if (formData?.is_edit) {
                // Update existing employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_todos/update`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            } else {
                // Create new employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_todos/create`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            }
            alert('ToDos saved successfully!');
        } catch (error) {
            console.error('Error saving todos:', error);
        }
    };
    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card card-primary'>
                    <div className='card-header'>
                        <h4>{formData?.is_edit ? 'Update what To-Do' : 'Add what to do'}</h4>
                    </div>
                    <div className='card-body p-2'>

                        <div>
                            <div className='row mt-2 mb-2'>
                                <div className='col-12'>
                                    <label>Employess</label>
                                    <select className='form-select' id='employee_id'  name='employee_id' value={formData?.employee_id}
                                        onChange={handleChange}>
                                        <option value={''}> - SELECT - </option>
                                        {
                                            EmployeeData && EmployeeData?.length > 0 ? 
                                            EmployeeData.map((val, index) => (
                                                <option value={val.id}>{val.full_name} ({val.email_id})</option>
                                            ))
                                            :''
                                        }
                                    </select>
                                </div>
                                <div className='col-12'>
                                    <label  className='mt-2 mb-2'>Title </label>
                                    <input className='form-control' id='title'  name='title' value={formData?.title} onChange={handleChange} />
                                </div>
                            </div>
                           
                            <div className='row mt-2 mb-2'>
                                <div className='col-12'>
                                    <label>Description</label>
                                    <textarea className='form-control' id='description'  name='description' value={formData?.description} onChange={handleChange} ></textarea>
                                </div>

                                <div className='col-12 mt-2'>
                                    <label>Date</label>
                                    <input className='form-control' id='start_date'  name='start_date' value={formData?.start_date} onChange={handleChange} />
                                </div>
                                <div className='col-12'>
                                    <label>Deadlines in days</label>
                                    <textarea className='form-control' id='deadline'  name='deadline' value={formData?.deadline} onChange={handleChange} ></textarea>
                                </div>
                            </div>
                            <div className='col-12'>
                                <label>Progress</label>
                                <select name="progress" id="progress" value={formData?.progress} onChange={handleChange} className="form-select">
                                    <option value="">- Select progress rate -</option>
                                    <option value="Assigned">Assigned</option>
                                    <option value="Started">Started</option>
                                    <option value="In Review">In Review</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </div>
                            <div className='col-12'>
                                <label>Priority</label>
                                <select name="priority" id="priority" value={formData?.priority} onChange={handleChange} className="form-select">
                                    <option value=""> - work piority - </option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </select>
                            </div>

                        </div>


                    </div>
                    <div className='form-group'>
                        <button
                            className='btn btn-success text-white'
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
