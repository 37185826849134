import React from 'react'
import { sortable } from 'react-sortable';

import { NavLink } from 'react-router-dom'

// export default class SidebarSubMenuComponents extends Component {
class Item2 extends React.Component {
    render() {
        // console.log();
        const prop_data = this.props.items[this.props['data-id']];
        // if(prop_data.type === "accordian")
        //     return (

        //         <li className="nav-item" {...this.props}>
        //             <div className="accordion" role="tablist" id={`accordion-${prop_data.id}`} style={{background: 'rgba(255,255,255,0)',borderRadius: '0'}}>
        //                 <div className="accordion-item" style={{borderStyle: 'none', borderRadius: '0px'}}>
        //                     <h2 className="accordion-header" role="tab" style={{borderStyle: 'none', borderRadius: '0px'}}>
        //                         <button className="accordion-button collapsed text-light" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-${prop_data.id} .item-1`} aria-expanded="false" aria-controls={`accordion-${prop_data.id} .item-1`} style={{background: '#AF1F24', borderStyle: 'none', padding: '16px', fontSize: '13.6px',borderRadius: '0px'}}>{this.props.children}</button>
        //                     </h2>
        //                     <div className="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent={`#accordion-${prop_data.id}`} style={{padding: '0px',margin: '0px'}}>
        //                         <div className="accordion-body">
        //                             <NavLink className="d-block td-none" to="/Architecture/Dashboard" style={{padding: '5px 5px'}}>Dashboard</NavLink>
        //                             <NavLink className="d-block td-none" to="/Architecture/Servers" style={{padding: '5px 5px'}}>Servers</NavLink>
        //                             <NavLink className="d-block td-none" to="/Architecture/TypeOfService" style={{padding: '5px 5px'}}>Types of service</NavLink>
        //                             <NavLink className="d-block td-none" to="/Architecture/SupportTickets" style={{padding: '5px 5px'}}>Support Tickets</NavLink>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </li>
        //     )
        // else 
        if(prop_data.type === "link")
            return (

                // <li className="nav-item" {...this.props}>
                    <NavLink className="nav-sub-link d-block td-none" to={`${prop_data.link}`}>{this.props.children}</NavLink>
                // </li>
            )
        else 
            return (

                // <li className="nav-item" {...this.props}>
                    <NavLink className="nav-sub-link d-block td-none" to={`${prop_data.link}`}>{this.props.children}</NavLink>
                // </li>
            )

    }
}
var SidebarSubMenuComponents = sortable(Item2);

export default SidebarSubMenuComponents;
