import React from 'react'
import ReactSession from "../../../components/ReactSession";

function PlaceOfWork() {
  ReactSession.setStoreType("localStorage");
  return (
    <div className='mt-4'> 
    <h6 className='fw-bold text-dark'>Send an alert to your employees</h6>
      <p className='text-dark mt-2'>Send important messages to your employees. Your message will be sent as push notifications to users with our mobile app installed. Web users will see the message in the notification menu when they log in. There are limited charecters avialable for messages. if you need to include a link, consider using a URL shortning service such as Bitly</p>
    <div className="row mt-3">
      <div className='col-7'>
      <a href="/#">Please note Only administrators can adjust this setting</a>
      </div>
      <div className='col-5'>
        <label className="btn btn-info" for="option5"><input type="radio" className="" name="options-base" id="option5" autocomplete="off" checked /> All</label>
        <label className="btn btn-info ms-3" for="option6"><input type="radio" className="" name="options-base" id="option6" autocomplete="off" /> Specific</label>
      </div>
    </div>
    <div className="d-flex mt-3">
      <button className='btn btn-primary'>Cancle</button>
      <button className='btn btn-secondary ms-2'>Confirm</button>
    </div>
    </div>
  )
}

export default PlaceOfWork