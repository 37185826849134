import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { calculateAge } from '../../utils/DateFunctions'
import moment from 'moment';
import ReactSession from "../../components/ReactSession";

export default function AppointmentRequest() {
  ReactSession.setStoreType("localStorage");
    const {appointment_id} = useParams();
    const [formData, setFormData] = useState({
    server_id: ReactSession.get('server_id'),
    is_edit: false,
        appointment_id: appointment_id,
        first_name: "",
        last_name: "",
        email_id: "",
        company_name: "",
        account_no: "",
        phone_no: "",
        account_type: "",
        preferred_start_date: "",
        preferred_end_date: "",
        details: ""
    });

    useEffect(() => {
        if (appointment_id) {
            fetchEmployeeDetails();
        }
    }, [appointment_id]);
    const fetchEmployeeDetails = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_appointments/fetch_single`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
    server_id: ReactSession.get('server_id'),
                    id: appointment_id,
                }
            });
            const { first_name,last_name,email_id,phone_no,company_name,account_type,preferred_start_date,preferred_end_date,details,account_no, } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { first_name: '', last_name: '', email_id: '', phone_no: '', company_name: '', account_type: '', preferred_start_date: '', preferred_end_date: '', details: '', account_no: '' };
            setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, first_name: first_name,last_name: last_name,email_id: email_id,phone_no: phone_no,company_name: company_name,account_type: account_type,preferred_start_date: moment(preferred_start_date).format('YYYY-MM-DD'),preferred_end_date: moment(preferred_end_date).format('YYYY-MM-DD'),details: details,account_no: account_no });
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDob = (e) => {
        // handleChange(e); 
        const { name, value } = e.target;
        const age = calculateAge(e.target.value)
        setFormData({ ...formData, ["age"]: age, [name]: value });
    }

    const handleSave = async () => {
        try {
            if (formData?.is_edit) {
                // Update existing employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_appointments/update`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            } else {
                // Create new employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_appointments/create`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            }
            alert('Appointment saved successfully!');
        } catch (error) {
            console.error('Error saving employee:', error);
        }
    };

    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card card-primary'>
                    <div className='card-header'>
                        <h4>{formData?.is_edit ? 'Update HR Documentation Appointment' : 'Request a HR Documentation Appointment'}</h4>
                    </div>
                    <div className='card-body p-2'>

                        <div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-6'>
                                        <label>First Name</label>
                                        <input type='text' className='form-control' name='first_name' value={formData.first_name} onChange={handleChange} placeholder='Enter First Name' required />
                                    </div>
                                    <div className='col-6'>
                                        <label>Last Name</label>
                                        <input type='text' className='form-control' name='last_name' value={formData.last_name} onChange={handleChange} placeholder='Enter Last Name' required />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-6'>
                                        <label>Email Address</label>
                                        <input type='email' className='form-control' name='email_id' value={formData.email_id}  onChange={handleChange} placeholder='jane.smith@acmeinc.com' required />
                                    </div>
                                    <div className='col-6'>
                                        <label>Company</label>
                                        <input type='text' className='form-control' name='company_name' value={formData.company_name} onChange={handleChange} placeholder='ACME inc.' required />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Account Number</label>
                                        <input type='number' className='form-control' name='account_no' value={formData.account_no} onChange={handleChange} placeholder='Account number' required />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Telephone Number</label>
                                        <input type='number' className='form-control' name='phone_no' value={formData.phone_no} onChange={handleChange} placeholder='8956321456' required />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Appointment type</label>
                                        <select id="inputState" name='account_type' value={formData.account_type} onChange={handleChange} className="form-control">
                                            <option selected>Please select</option>
                                            <option>Specific document request / query</option>
                                            <option>Review of existing documents</option>
                                            <option>HR advice</option>
                                            <option>H&S advice</option>
                                            <option>face-to-face support</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Preferred date( s )</label>
                                        <div className='row align-items-center'>
                                            <div className='col-5'>
                                                <input type='date' name='preferred_start_date' value={formData.preferred_start_date}  onChange={handleChange} className='form-control' />
                                            </div>
                                            <i className="fas fa-arrows-alt-h col-1 text-center" style={{ fontSize: '1.4rem' }}></i>
                                            <div className='col-5'>
                                                <input type='date' name='preferred_end_date' value={formData.preferred_end_date} onChange={handleChange} className='form-control' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Other details</label>
                                        <textarea className='form-control' name='details' onChange={handleChange} value={formData.details} cols='30' rows={4} placeholder='Please enter any other deatils here' />
                                    </div>
                                </div>
                        </div>
                        <div className='form-group'>
                            <button
                                className='btn btn-success text-white'
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
