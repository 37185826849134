import React from "react";
import MyDocuments from "./components/MyDocuments";
import ReactSession from "../../components/ReactSession";

function Documents() {
  ReactSession.setStoreType("localStorage");
  return (
    <>
      <h2 className="mb-3">Documents</h2>
      <MyDocuments  />
    </>
  );
}

export default Documents;
