import React from "react";
import {DateRangePicker} from "rsuite";
import ReactSession from "../../../components/ReactSession";

function Labs() {
  ReactSession.setStoreType("localStorage");
  return (
    <div className="w-100 mt-3">
      <div className="row">
        <div className="col-sm-4 col-lg-2">
          <label className="form-label" htmlFor="filter_by_employee">
            Filter By Employees
          </label>
          <input
            type="text"
            id="filter_by_employee"
            className="form-control"
            placeholder="Select Employees"
          />
        </div>
        <div className="col-sm-4 col-lg-2">
          <label className="form-label" htmlFor="filter_by_status">
            Filter By Status
          </label>
          <select
            name="filter_by_status"
            id="filter_by_status"
            className="form-control"
          >
            <option value="View All">View All</option>
            <option value="Active">Active</option>
            <option value="In-Active">In-Active</option>
          </select>
        </div>
        <div className="col-sm-4 col-lg-4">
          <label className="form-label" htmlFor="date_range">
            Date Range
          </label>
          <div className="row">
            <div className="col-8">
              <DateRangePicker
                format="MM/dd/yyyy"
                character=" - "
                className="w-100"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-lg-2 mt-4">
          <button className="btn btn-danger">Add historical clock-in</button>
        </div>
        <div className="col-sm-4 col-lg-2 mt-4">
          <button className="btn btn-primary">Export to CSV</button>
        </div>
      </div>

      <div className="mt-3">
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "0.9rem" }}>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Employee
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Clock in
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Clock out
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Breaks
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Total
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Incl. breaks <input type="checkbox" name="" id="" />
              </th>
            </tr>
          </thead>
          <tbody className="Labs" style={{ fontSize: "0.9rem" }}>
            <tr>
              <td className="text-primary" style={{ fontWeight: "500" }}>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="d-flex justify-content-center align-items-center col-4 bg-primary"
                    style={{
                      width: "65px",
                      height: "65px",
                      borderRadius: "50%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        color: "white",
                      }}
                    >
                      TD
                    </p>
                  </div>
                  <div className="col-8">
                    <p
                      className="text-primary"
                      style={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      Todd
                    </p>
                    <span>Care Assistant</span>
                    <br />
                    <span
                      className="text-primary"
                      style={{ fontSize: "0.9rem", fontWeight: "500" }}
                    >
                      Clock out
                    </span>
                  </div>
                </div>
              </td>
              <td className="text-dark">16:01</td>
              <td className="text-dark">--:--</td>
              <td className="text-dark">0hrs 00mins</td>
              <td className="text-dark">Ongoing</td>
              <td className="text-dark">
                <button>
                  <i className="fa fa-pen"></i>
                </button>
                <button className="ms-2">
                  <i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr></tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Labs;
