import {
    MAKE_ENDUSER_LOGIN,FIND_USER,GET_OTP,VERIFY_OTP,VALID_TOKEN,
} from "./type";
import SignIn from "../service/SignIn";
export const makeLogin = (data) => async (dispatch) => {
    try {
        const res = await SignIn.makeLogin(data);
        dispatch({
            type: MAKE_ENDUSER_LOGIN,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const findUser = (data) => async (dispatch) => {
    try {
        const res = await SignIn.findUser(data);
        dispatch({
            type: FIND_USER,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getEmailOtp = (data) => async (dispatch) => {
    try {
        const res = await SignIn.getEmailOtp(data);
        dispatch({
            type: GET_OTP,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getVerifyEmailOtp = (data) => async (dispatch) => {
    try {
        const res = await SignIn.getVerifyEmailOtp(data);
        dispatch({
            type: VERIFY_OTP,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const genarateValidToken = (data) => async (dispatch) => {
    try {
        const res = await SignIn.genarateValidToken(data);
        dispatch({
            type: VALID_TOKEN,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};