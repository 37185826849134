import {

  RETRIEVE_GOOGLE_CALENDAR_EVENTS,
  CHANGE_RESERVATION_VIEW,

  EQUIPMENT_LIST,
  EQUIPMENT_TYPE_LIST,

} from "../actions/type";
const initialState = [];
function ARCVisitCalendarReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_GOOGLE_CALENDAR_EVENTS:
      state = (payload.error === 1)?initialState:payload;
      return {data: [...state], payload};
    default:
      return state;
  }
};
// function ReserveEquipmentPageReducer(state = initialState, action) {
//   const { type, payload } = action;

//   switch (type) {
//     case EQUIPMENT_LIST:
//       state = (payload.error === 1)?initialState:payload;
//       return {data: [...state], payload};
//     default:
//       return state;
//   }
// };
// function ReserveEquipmentTypePageReducer(state = initialState, action) {
//   const { type, payload } = action;

//   switch (type) {
//     case EQUIPMENT_TYPE_LIST:
//       state = (payload.error === 1)?initialState:payload;
//       return {data: [...state], payload};
//     default:
//       return state;
//   }
// };




function ARCVisitCalendarUIReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_RESERVATION_VIEW:
      return payload;
    default:
      return state;
  }
};
export {ARCVisitCalendarReducer, ARCVisitCalendarUIReducer};