import {
    CREATE_ARCHITECTURECOMPANYLEADS,
    RETRIEVE_ARCHITECTURECOMPANYLEADS,
    RETRIEVE_ARCHITECTURECOMPANYCONVERTEDLEADS,
    RETRIEVE_ARCHITECTURECOMPANYPENDINGLEADS,
    RETRIEVESINGLE_ARCHITECTURECOMPANYLEADS,
    UPDATE_ARCHITECTURECOMPANYLEADS,
    DELETE_ARCHITECTURECOMPANYLEADS,
  } from "../actions/type";
  const initialState = [];
  function ARCCompanyConvertedLeadsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURECOMPANYLEADS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURECOMPANYCONVERTEDLEADS:
        state = (payload.error === 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURECOMPANYLEADS:
        state = (payload.error === 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURECOMPANYLEADS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURECOMPANYLEADS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  function ARCCompanyPendingLeadsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCHITECTURECOMPANYLEADS:
        return {data: state.data, payload};
      case RETRIEVE_ARCHITECTURECOMPANYPENDINGLEADS:
        state = (payload.error === 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCHITECTURECOMPANYLEADS:
        state = (payload.error === 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCHITECTURECOMPANYLEADS:
        return {data: state.data, payload};
      case DELETE_ARCHITECTURECOMPANYLEADS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCCompanyConvertedLeadsReducer, ARCCompanyPendingLeadsReducer};