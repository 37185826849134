import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactSession from "../../../components/ReactSession";

import { calculateAge } from '../../../utils/DateFunctions'

export default function Create() {
  ReactSession.setStoreType("localStorage");
  const { id } = useParams();
  const [formData, setFormData] = useState({
    server_id: ReactSession.get('server_id'),
    is_edit: false,
    id: id,
    team_name: "",
  });

  useEffect(() => {
    if (id) {
      fetchTeamDetails();
    }
  }, [id]);


  const fetchTeamDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_teams/fetch_single_team`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          server_id: ReactSession.get('server_id'),
          id: id,
        }
      });
      const { team_name } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { team_name: '' };
      setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, team_name: team_name });
    } catch (error) {
      console.error('Error fetching team details:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (formData?.is_edit) {
        // Update existing team
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_teams/update_team`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData
        });
      } else {
        // Create new team
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_teams/create_team`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData
        });
      }
      alert('Team saved successfully!');
    } catch (error) {
      console.error('Error saving team:', error);
    }
  };

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='card card-primary'>
          <div className='card-header'>
            <h4>{id ? 'Edit Team' : 'Add Team'}</h4>
          </div>
          <div className='card-body p-2'>
            <div className='form-group'>
              <label className='mt-2 mb-2'>Team Name</label>
              <input
                type='text'
                name='team_name'
                className='form-control'
                placeholder='Enter Team Name'
                value={formData.team_name}
                onChange={handleChange}
              />
            </div>
            <div className='form-group text-right'>
              <button
                className='btn btn-success text-white'
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
