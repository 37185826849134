import {
    CREATE_SERVICE_USER_ACTIVITIES,
    RETRIEVE_SERVICE_USER_ACTIVITIES,
    RETRIEVESINGLE_SERVICE_USER_ACTIVITIES,
    UPDATE_SERVICE_USER_ACTIVITIES,
    DELETE_SERVICE_USER_ACTIVITIES,
  } from "../actions/type";
  const initialState = [];
  function ServiceUserActivitiesReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SERVICE_USER_ACTIVITIES:
        return {data: state.data, payload};
      case RETRIEVE_SERVICE_USER_ACTIVITIES:
        state = (payload.error === 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SERVICE_USER_ACTIVITIES:
        state = (payload.error === 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SERVICE_USER_ACTIVITIES:
        return {data: state.data, payload};
      case DELETE_SERVICE_USER_ACTIVITIES:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ServiceUserActivitiesReducer};