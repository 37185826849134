import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactSession from "../../../components/ReactSession";

export default function CreateAddress() {
  ReactSession.setStoreType("localStorage");
  const { employee_id } = useParams();
  const [formData, setFormData] = useState({
    server_id: ReactSession.get('server_id'),
    is_edit: false,
    employee_id: employee_id,
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    country: '',
    post_code: ''
  });

  useEffect(() => {
    if (employee_id) {
      fetchEmployeeDetails();
    }
  }, [employee_id]);
  const fetchEmployeeDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_single_employee_address_details`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          server_id: ReactSession.get('server_id'),
          employee_id: employee_id,
        }
      });
      const { address_1, address_2, city, state, country, post_code } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { address_1: '', address_2: '', city: '', state: '', country: '', post_code: '' };
      setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, address_1: address_1, address_2: address_2, city: city, state: state, country: country, post_code: post_code });
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (formData?.is_edit) {
        // Update existing employee
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_employee_address`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData
        });
      } else {
        // Create new employee
        await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/create_employee_address`, {
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData
        });
      }
      alert('Employee saved successfully!');
    } catch (error) {
      console.error('Error saving employee:', error);
    }
  };

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='card card-primary'>
          <div className='card-header'>
            <h4>{formData?.is_edit ? 'Edit Employee Address' : 'Add Employee Address'}</h4>
          </div>
          <div className='card-body p-2'>
            <div className='form-group'>
              <label>Address Line 1</label>
              <input
                type='text'
                name='address_1'
                className='form-control'
                placeholder='Enter Address Line 1'
                value={formData.address_1}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Address Line 2</label>
              <input
                type='text'
                name='address_2'
                className='form-control'
                placeholder='Enter Address Line 2'
                value={formData.address_2}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>City</label>
              <input
                type='text'
                name='city'
                className='form-control'
                placeholder='Enter City'
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>State</label>
              <input
                type='text'
                name='state'
                className='form-control'
                placeholder='Enter State'
                value={formData.state}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Country</label>
              <input
                type='text'
                name='country'
                className='form-control'
                placeholder='Enter Country'
                value={formData.country}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <label>Postal Code</label>
              <input
                type='text'
                name='post_code'
                className='form-control'
                placeholder='Enter Postal Code'
                value={formData.post_code}
                onChange={handleChange}
              />
            </div>
            <div className='form-group'>
              <button
                className='btn btn-success text-white'
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
