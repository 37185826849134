import React from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import ReactSession from "../../../components/ReactSession";

function WorkingPatterns() {
  ReactSession.setStoreType("localStorage");
  return (
    <div className="w-100 mt-4">
      <div className="row">
        <div className="col-sm-6 col-lg-6">
          <h5 className="text-dark fw-bold">Working time patterns</h5>
        </div>
        <div className="col-sm-6 col-lg-6 text-end">
          <button className="btn btn-danger">Add new patterns</button>
        </div>
      </div>
      <p className="text-dark">New employees imported into the system will be assigned to: <b>Mon-Fri 9-5</b></p>
      <div className="d-flex mt-2">
        <button className="btn text-primary fw-bold" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">Expand all</button>
        <button className="btn text-primary fw-bold " data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">Collapse all</button>
      </div>
      <div className="mt-2">
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "0.9rem" }}>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                  width: '45%'
                }}
              >
                Name
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                  width: '45%'
                }}
              >
                Days
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                  width: '5%'
                }}
              >
                Assigned
              </th>
              <th style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                  width:'5%'
                }}>
                </th>
            </tr>
          </thead>
          <tbody className="WorkingPatterns accordion" id="accordionExample" style={{ fontSize: "0.9rem" }}>
            <tr className="accordion-bt collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <td className="text-dark">Jacqui</td>
              <td className="text-dark">Tuesday - Thursday</td>
              <td className="text-dark">
              <span><i className="fa fa-user-friends"></i>1</span>
              <button className="btn text-primary" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/#">Action</a></li>
                <li><a className="dropdown-item" href="/#">Another action</a></li>
                <li><a className="dropdown-item" href="/#">Something else here</a></li>
              </ul>
              </td>
              <td><span className="accordion-button collapsed" style={{height: '1rem',background:'transparent'}}></span></td>
            </tr>
            <tr id="collapseTwo"className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <td colSpan={4}>
                <p className="text-dark"><b>Tue</b> 09:00 - 17:00 60 mins break</p>
                <p className="text-dark"><b>Wed</b> 09:00 - 17:00 60 mins break</p>
                <p className="text-dark"><b>Thu</b> 09:00 - 17:00 60 mins break</p>
                <p className="text-dark"><b>3 working days</b> totaling <b>21 hrs</b> excluding breaks.</p>
                <p className="text-dark">Public holidays are deducated from the annual leave entitlement</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WorkingPatterns;
