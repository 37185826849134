import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ReactSession from "../../components/ReactSession";

export default function Index() {
  ReactSession.setStoreType("localStorage");
  const [search, setsearch] = useState("");
  const [formData, setFormData] = useState([]);
    useEffect(() => {
        fetchOvertime();
    }, [])

  const fetchOvertime = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_overtimes/fetch`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        response?.data &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        setFormData(response?.data?.data);
      }else{setFormData([])}
    } catch (error) {
      console.error("Error fetching clock in location:", error);
    }
  };
  const handleSearch = (e) => {
    setsearch(e.target.value);
  };
  const handleTrash = async (overtime_id = 1) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_overtimes/delete`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          id: overtime_id,
          server_id: ReactSession.get('server_id')
        }
      });
      fetchOvertime();
    } catch (error) {
      console.error('Error fetching Overtime details:', error);
    }
  }
  const handleStatus = async (status,overtime_id = 1) => {
    try {
      const cStatus = status === 0 ?1:0
      await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_overtimes/change_status`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          status:cStatus,
          id: overtime_id,
          server_id: ReactSession.get('server_id')
        }
      });
      fetchOvertime();
    } catch (error) {
      console.error('Error fetching Time off in lieu details:', error);
    }
  }
  const handleEdit = (overtime_id = 1) => {
    window.location.href = `/Architecture/Hr/Overtime/Create/${overtime_id}`;
  }
  const dateTimeConvert = (date,time) => {
    return moment(new Date(new Date(date).toDateString() + " " + time)).format('LLL')
  }
  return (
    <>
    <h2>All Overtimes</h2>

    <div>
    <div className="row mt-3">
      <div className="col-3">
        <input
          placeholder="Search by employees name"
          className="form-control"
          style={{
            border: "2px solid rgba(0,0,0,0.3)",
            color: "black",
          }}
          onChange={handleSearch}
        />
      </div>
      <div className="col-2">
        <a href='/Architecture/Hr/Overtime/Create'
          className="btn btn-danger"
        >
          Add new Request
        </a>
        {/* ----------------------- */}
      </div>
    </div>
    <div className="row mt-3">
      <table className="table table-striped">
        <thead>
          <tr style={{ fontSize: "0.9rem" }}>
            <th
              scope="col"
              style={{
                color: "#073a5d",
                backgroundColor: "#EBF7FF",
                borderBottom: "2px solid rgba(0,0,0,0.2)",
              }}
            >Name</th>
            <th
              scope="col"
              style={{
                color: "#073a5d",
                backgroundColor: "#EBF7FF",
                borderBottom: "2px solid rgba(0,0,0,0.2)",
              }}
            >
              Overtime Start
            </th>
            <th
              scope="col"
              style={{
                color: "#073a5d",
                backgroundColor: "#EBF7FF",
                borderBottom: "2px solid rgba(0,0,0,0.2)",
              }}
            >
              Overtime End
            </th>
            <th
              scope="col"
              style={{
                color: "#073a5d",
                backgroundColor: "#EBF7FF",
                borderBottom: "2px solid rgba(0,0,0,0.2)",
              }}
            >
              Overtime in sec
            </th>
            <th
              scope="col"
              style={{
                color: "#073a5d",
                backgroundColor: "#EBF7FF",
                borderBottom: "2px solid rgba(0,0,0,0.2)",
              }}
            >
              Reward
            </th>
            <th
              scope="col"
              style={{
                color: "#073a5d",
                backgroundColor: "#EBF7FF",
                borderBottom: "2px solid rgba(0,0,0,0.2)",
              }}
            >
              Pay rate
            </th>
            <th
              scope="col"
              style={{
                color: "#073a5d",
                backgroundColor: "#EBF7FF",
                borderBottom: "2px solid rgba(0,0,0,0.2)",
              }}
            >
              Notes
            </th>
            <th
              scope="col"
              style={{
                color: "#073a5d",
                backgroundColor: "#EBF7FF",
                borderBottom: "2px solid rgba(0,0,0,0.2)",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="history" style={{ fontSize: "0.9rem" }}>
          {formData && formData.length > 0 ? (
            formData.filter((val) => {
              return search.toLowerCase() === ""
                ? val
                : val?.full_name.toLowerCase().includes(search) 
            }).map((data, index) => {
              return (
                  <tr className="collapsed" key={index}>
                  <td
                          className="text-primary"
                          style={{ fontWeight: "500" }}
                        >
                          <div className="row align-items-center justify-content-center">
                            <div
                              className="d-flex justify-content-center align-items-center col-4 bg-primary"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "1.1rem",
                                  fontWeight: "500",
                                  color: "white",
                                }}
                              >
                                {data.full_name.trim().split(' ').map((part, index) => index === 0 ? part.charAt(0).toUpperCase() : part.charAt(0)).join('').toUpperCase()}
                              </p>
                            </div>
                            <div className="col-8">
                              <p
                                className="text-primary"
                                style={{ fontSize: "1rem", fontWeight: "600" }}
                              >
                                {data.full_name}
                              </p>
                              <span>{data.role}</span>
                            </div>
                          </div>
                        </td>
                    <td className="text-dark">
                      <p>{dateTimeConvert(data.overtime_start_date,data.overtime_start_time)}</p>
                    </td>
                    <td className="text-dark">
                      <p>{dateTimeConvert(data.overtime_end_date,data.overtime_end_time)}</p>
                    </td>
                    <td className="text-dark">
                      <p>{data.overtime_length_in_seconds}</p>
                    </td>
                    <td className="text-dark">
                      <p>{data.overtime_reward}</p>
                    </td>
                    <td className="text-dark">
                      <p>{data.pay_rate}</p>
                    </td>
                    <td className="text-dark">
                      <p>{data.notes}</p>
                    </td>
                    <td className="text-dark">
                    <button className="btn" onClick={()=>handleStatus(data.status,data.id)}>
                        <i className={`fa fa-toggle-${data.status?'on text-success':'off text-danger'}`}></i>
                      </button>
                      <button className=" btn btn-info" onClick={()=>handleEdit(data.id)}>
                        <i className="fa fa-pen"></i>
                      </button>
                      <button className="ms-2 btn btn-primary" onClick={() => handleTrash(data.id)}>
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
              );
            })
          ) : (
            <tr>
              <th className="text-center" colSpan={5}>
                No Records Avialable...
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
  </>
  )
}
