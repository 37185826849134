import React from 'react'

import AnnualLeaveBalance from './Stats'
import ReactSession from "../../../components/ReactSession";

export default function Index() {
  ReactSession.setStoreType("localStorage");
  return (
    <div className='container-fluid'>
        <AnnualLeaveBalance />
    </div>
  )
}
