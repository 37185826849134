import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSession from "../../../components/ReactSession";

export default function List() {
  ReactSession.setStoreType("localStorage");
  console.clear()
  const [teamData, setTeamData] = useState([]);

  const InitialData = [];
  const navigate = useNavigate();

  const [search, setsearch] = useState("");
  const handleSearch = (e) => {
    setsearch(e.target.value);
  };

  useEffect(() => {
    fetchTeamDetails();
  }, []);

  const handleEdit = (team_id = 1) => {
    window.location.href = `/Architecture/Hr/Teams/Create/${team_id}`;
  }
  const handleTrash = async (team_id = 1) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_teams/delete_team`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          server_id: ReactSession.get('server_id'),
          id: team_id,
        }
      });
      fetchTeamDetails();
    } catch (error) {
      console.error('Error fetching team details:', error);
    }
  }

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      server_id: ReactSession.get('server_id'),
    }
  };
  const fetchTeamDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_teams/fetch_team`, axiosConfig);
      setTeamData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
    } catch (error) {
      console.error('Error fetching team details:', error);
    }
  };

  const handleTeamDetailsView = (id) => {
    navigate(`/Architecture/Hr/EmployeeProfile/${id}`);
  };

  const handleAssignTeamEmployees = (id) => {
    navigate(`/Architecture/Hr/Teams/${id}/AssignEmployees`);
  };
  return (
    <>
      <div style={{ marginTop: "0.3rem" }}>
        <button className="btn-p" onClick={() => { window.location.href = "/Architecture/Hr/Teams/Create" }}>
          Add Team
        </button>

        <div style={{ marginTop: "0.7rem", marginLeft: "0", paddingLeft: "0" }}>
          <span
            style={{ fontSize: "1rem", fontWeight: "500", margin: "0.5rem 0" }}
          >
            Find
          </span>
          <div style={{ position: "relative", width: '50%' }}>
            <input
              onChange={handleSearch}
              className="form-control"
              placeholder="Search Teams..."
              style={{
                width: "100%",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                border: "2px solid rgba(0,0,0,0.2)",
                color: "black",
              }}
            />

            <i
              className="fas fa-search"
              style={{
                position: "absolute",
                right: "0.4rem",
                top: "0.6rem",
                fontSize: "1.2rem",
                color: "rgba(0,0,0,0.5)",
              }}
            ></i>
          </div>
        </div>

        <div className="cards-emp">
          {teamData && teamData.length > 0 ? (
            teamData
              .filter((val, index) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.full_name.toLowerCase().includes(search);
              })
              .map((data, i) => {
                return (
                  <>
                    <div className='col-md-4'>
                      <div className="card-p d-block">
                        <div className="row">
                          <div className="col-10">
                            <div className="row align-items-center justify-content-center">
                              <div
                                className="d-flex justify-content-center align-items-center col-4 bg-primary"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "500",
                                    color: "white",
                                  }}
                                >
                                  {data.team_name && data.team_name.trim().split(' ').map((part, index) => index === 0 ? part.charAt(0).toUpperCase() : part.charAt(0)).join('').toUpperCase()}
                                </p>
                              </div>
                              <div className="col-8">
                                <p
                                  className="text-primary"
                                  style={{ fontSize: "1rem", fontWeight: "600" }}
                                >
                                  {data.team_name}
                                </p>
                                <span>{data.total_members} Members</span>
                              </div>
                            </div>
                            <button
                              onClick={() => handleAssignTeamEmployees(data.id)}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.3rem",
                                padding: "0.3rem rem",
                                fontWeight: "500",
                                fontSize: "0.9rem",
                                borderRadius: "0.3rem",
                                color: "#d14774",
                                marginLeft: '5rem',
                              }}
                            >
                              Assign / Remove Team Members <i className="fas fa-chevron-right"></i>{" "}
                            </button>
                          </div>
                          <div className="col-2">
                            <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleEdit(data.id)}><i className='fa fa-edit'></i></button>
                            <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleTrash(data.id)}><i className='fa fa-trash'></i></button>
                            {/* <button className='btn btn-warning btn-sm ml-1' onClick={(e) => handleAssignTeamEmployees(data.id)}><i className='fa fa-link'></i></button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
