import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../../../Firebase'
import { getFirestore, addDoc, collection, getDocs,onSnapshot,doc,get ,updateDoc} from "firebase/firestore";
function UpdateEmployee() {
    const {id} = useParams()
  const db = getFirestore();
  const [Loading,setLoading] = useState(false)
  const [Patienten,setPatienten] = useState([])
  const [userData,setUserData] = useState(
    {
      id: "Bikuda",
      homeLocation: {
        latitude: 22.541198,
        longitude: 88.35108
      },
      activeNow: true,
      driversLicense: {
        file: "",
        expiryDate: ""
      },
      studentLoanOptIn: 0,
      nextOfKin: {
        address: "",
        name: "",
        relation: "",
        phone: "",
        email: ""
      },
      bankDetails: {
        fullName: "",
        accountName: "",
        sortCode: "",
        bank: "",
        accountNumber: " "
      },
      visitAnalytics: {
        missed: 2,
        late: 0,
        early: 0,
        total: 5
      },
      hourlyRate: 0,
      phoneNumber: "56666552333",
      activeLocation: {
        geohash: "",
        geopoint: {
          latitude: 22.541404,
          longitude: 88.351784
        }
      },
      adminID: "Swarnendu",
      breakTime: {
        startTime: "",
        endTime: ""
      },
      username: "Bikuda",
      startShift: {
        endTime: "",
        startTime: ""
      },
      status: true,
      passportNumber: "",
      bankAddress: "",
      dob: "2012-08-03",
      phoneCode: "+91",
      mileage: 0,
      knownLanguages: [],
      employmentStatus: "",
      nyRate: 0,
      reactivateDate: null,
      lastSeen: {
        seconds: 1689678724,
        nanoseconds: 293999000
      },
      travelTimeRate: 0,
      nationality: "British",
      niNumber: "Not Added",
      terminatedDate: null,
      rate: {
        trainingRate: 0,
        frequency: "daily",
        annualLeaveRate: 0,
        hourlyRate: 0,
        travelTimeRate: 0.5,
        mileage: 12,
        additionalItems: [
          {
            item: "Item 1",
            price: 0
          },
          {
            item: "Item 2",
            price: 0
          },
          {
            item: "Item 3",
            price: 0
          },
          {
            item: "Item 4",
            price: 0
          },
          {
            item: "Item 5",
            price: 0
          }
        ],
        bhRate: 0,
        waitingTimeRate: 0,
        christmasRate: 0
      },
      internationalWorkerStatus: "",
      visaSponsorshipID: "",
      type: "Care Worker",
      workingTimeOptOut: "",
      left: false,
      knownAs: "Heru",
      contractType: "",
      annualLeave: 0,
      employeeID: "Not Added",
      createdAt: {
        seconds: 1689613228,
        nanoseconds: 480000000
      },
      gender: "Male",
      messageTokenIOS: "fFXPHI53gENHnOYCX-rG0C:APA91bFU9GDmgRBrj_CsjNfhWLCrEadL9rY0h-Z4yY7JifVTHLOGVaSnlBRt76hVqp18GmQMS0x-RIiIYO0ti0lbSDc5uHdnC8Y6xklhTpIY3l_ULUGZb-Lpk2usrvowanQCu3jbZBVw",
      pensionOptIn: 0,
      messageTokenAndroid: "",
      isTravelling: false,
      commuterType: "",
      christmas: 0,
      bankHoliday: 0,
      profilePicture: "https://firebasestorage.googleapis.com/v0/b/architectureapp-9e70d.appspot.com/o/placeholderProfile.png?alt=media&token=cb5e9801-01e9-477c-b04c-71d8e985150b",
      dbs: {
        expiryDate: "2024-04-30",
        file: "https://firebasestorage.googleapis.com/v0/b/architectureapp-9e70d.appspot.com/o/servers%2Fshaderbytes.com%2Fdbs_certificates%2Fdoc_1695878769487_IMG-20230927-WA0001.jpg.jpg?alt=media&token=71eb5793-bd2d-43b4-aa2e-0a71b473b027"
      },
      workingHours: "",
      email: "bikramjitsarkar2@gmail.com",
      nhsNumber: "123abcd",
      fullName: "Bikuda",
      carInsurance: {
        file: "https://firebasestorage.googleapis.com/v0/b/architectureapp-9e70d.appspot.com/o/servers%2Fshaderbytes.com%2FDBS_certificates%2Fdoc_023aae89c6c0166aabf63dc678464392.jpg?alt=media&token=aa5275d8-46a9-46ab-b801-544982b65ee5",
        expiryDate: "2023-12-01"
      },
      ratingCount: 0,
      uid: "",
      incomeTaxRate: 0,
      startDate: "07/11/2023",
      leftAt: {
        seconds: 1690385371,
        nanoseconds: 301000000
      },
      address: {
        postalCode: "",
        street: "",
        firstLineOfAddress: "",
        county: "",
        town: "Kolkata",
        secondLineOfAddress: "",
        country: "P88 Lake Road"
      },
      training: 0,
      waitingTime: 0,
      statistics: {
        totalVisits: 0,
        totalMileage: 0,
        expenses: 0,
        visitsCancelled: 0,
        totalMonthlyHours: 0,
        totalPay: 0,
        travelTime: 0
      },
      taxCode: "Not Added",
      religion: "Christian"
    }
  )
  const [userType,setuserType] = useState('')
  const [userName,setuserName] = useState('')
  const [userEmail,setuserEmail] = useState('')
  const [userPhone,setuserPhone] = useState({
    phone_code:'',
    phone_number:''
  })
  const fetchDataFromFirestore = async () => {
    try{
  
      setLoading(true)
  
    
      if(
        JSON.parse(
          localStorage.getItem("__react_session__")
        ).username !== null || JSON.parse(localStorage.getItem("__react_session__")).server_id !== null
      ){
        
        const subColRef = await collection(
          db,
          "servers",
          JSON.parse(localStorage.getItem("__react_session__")).server_id,
          "employees"
        );
    
        const qSnap = await getDocs(subColRef);
        // console.log(qSnap)
        // const res = await getDocs(subColRef);
        // console.log(qSnap.docs.map(d => ({id: d.id, ...d.data()})))
        console.log(qSnap)
        const newArr = qSnap.docs
          .map((d) => ({ id: d.id, ...d.data() }))
          .filter((data, i) => {


            // data.adminID === JSON.parse(
            //   localStorage.getItem("__react_session__")
            // ).username &&
            return ( data.id === id );
          });
          console.log(newArr)
          setUserData({...userData,...newArr[0]})
          
      setuserName(newArr[0]?.fullName)
      setuserType(newArr[0]?.type)
      setuserEmail(newArr[0]?.email)
      setuserPhone({...userPhone,phone_code:newArr[0]?.phoneCode,phone_number:newArr[0]?.phoneNumber})
      setLoading(false)
  
        // console.log(qSnap.docs);
        // setemp(newArr);
        // setdata(newArr)
        console.log('---',qSnap.docs.map((d) => ({ id: d.id, ...d.data() })));
        
      // };
      }else{
        setLoading(false)
  
        window.location.href = '/SignUp'
      }
    }
    catch(err){
      console.log(err)
    }
    
      };

    // useEffect(()=>{
    //     console.log(id)
    //     fetchDataFromFirestore()
    // },[])
const handleChange = (e)=>{
  setUserData({...userData,[e.target.name]:e.target.value.replace(/^\s+|\s+$/gm,'')})
  
}

const handleAddress = (e)=>{
  setUserData({...userData.address,[e.target.name]:e.target.value.trim()})
}

const handleBank = (e)=>{
  setUserData({...userData.bankDetails,[e.target.name]:e.target.value.trim()})
}

const handleProfile = ()=>{
  console.log(userData)

  const examcollref = doc(
    db,
    "servers",
    JSON.parse(localStorage.getItem("__react_session__")).server_id,
    "employees",
    id
  )
  updateDoc(examcollref,{
    ...userData
  } ).then(response => {
    alert("✅ Profile updated successfully !")
  }).catch(error =>{
    console.log(error.message)
  })
  
}
    useEffect(() => {
      
      fetchDataFromFirestore()

      console.log(userData)
    }, [id]);
  return (
    <div>
    <h3>Edit Profile</h3>
    <hr style={{borderTop:'1px solid rgba(0,0,0,0.2)'}} />

<div style={{marginLeft:'1.2rem'}}>
    <div style={{display:'flex',alignItems:'center',gap:'0.8rem'}}>
      <div style={{width:'100px',height:'100px',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',color:'white'}} >

        {userData?.profilePicture && userData?.profilePicture?.length>0?
        <img src={userData.profilePicture} style={{width:'100%'}} />
        :
        <i className="fas fa-user"></i>
       
        }

      </div>
        <div>
      <h5 style={{color:'#073A5D',fontWeight:'500',fontSize:'1.6rem',marginBottom:'0.5rem'}}>{userName}</h5>
      <span>{userType}</span>
      </div>

        <div style={{borderLeft:'1px solid rgba(0,0,0,0.3)',padding:'0.8rem',marginLeft:'1rem',paddingLeft:'1rem'}}>

        <h4 style={{marginBottom:'0.5rem'}}>Contact Info</h4>

        <p style={{display:'flex',alignItems:'center',gap:'0.6rem',fontSize:'1rem',fontWeight:'500'}}> <span style={{fontSize:'1.3rem'}}><i className="fas fa-envelope"></i></span> <a href={`mailto:${userEmail}`}  > {userEmail} </a>  </p>
        <p style={{display:'flex',alignItems:'center',gap:'0.6rem',fontSize:'1rem',fontWeight:'500'}}> <span style={{fontSize:'1.3rem'}}><i className="fas fa-phone-alt"></i></span> {userPhone?.phone_code}&nbsp;{userPhone?.phone_number}  </p>

        </div>

    </div>

    <div style={{margin:'1.3rem 0',display:'flex',gap:'1rem'}}>
    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Full Name</label>
      <input type='text' value={userData?.fullName} name='fullName' placeholder='Enter Name' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Known As</label>
      <input type='text' value={userData?.knownAs} name='knownAs' placeholder='Enter Known As' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column',position:'relative'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Gender</label>
      {/* <input type='text' value={userData?.gender} name='gender' placeholder='Enter Gender' className='form-control' onChange={handleChange} /> */}
      <select className='form-control' name='gender' value={userData?.gender} onChange={handleChange}>  
      <option  disabled selected>Select Gender </option>
      <option value="Male" selected>Male</option>
      <option  value="Female" selected>Female</option>
      <option value="Other" >Other</option>
      </select>
      <i className="fas fa-chevron-down" style={{position:'absolute',top:'2rem',right:'0.4rem'}}></i>
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Date of Birth</label>
      <input type='date' value={ userData?.dob?.match(/^\d{4}-\d{2}-\d{2}$/)? userData?.dob : userData?.dob?.split('-').reverse().join('-')} name='dob' placeholder='Enter Date Of Birth' className='form-control' onChange={handleChange} />
    </div>

    </div>

    <div style={{margin:'1.3rem 0',display:'flex',gap:'1rem'}}>
    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>UserName</label>
      <input type='text' value={userData?.username} name='username' placeholder='Enter UserName' className='form-control' onChange={handleChange} />
    </div>

    {/* <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Known As</label>
      <input type='text' value={userData?.knownAs} name='knownAs' placeholder='Enter Known As' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Gender</label>
      <input type='text' value={userData?.gender} name='gender' placeholder='Enter Known As' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Date of Birth</label>
      <input type='date' value={userData?.dob} name='dob' placeholder='Enter Known As' className='form-control' onChange={handleChange} />
    </div> */}

    </div>

    <button className='btn btn-primary' onClick={handleProfile} style={{margin:'0rem 0 0.8rem 0',width:'100%',fontSize:'0.9rem'}} >Update Profile</button>

    <h6>Additional Details</h6>

    <h5 style={{margin:'0',padding:'0',color:'#D14774',width:'100%',backgroundColor:'rgba(0,0,0,0.1)',padding:'1rem',borderRadius:'0.3rem',fontSize:'1rem'}}>Address Details</h5>

<div style={{margin:'1.3rem 0',display:'flex',flexDirection:'column',gap:'0.2rem'}}>

<div style={{display:'flex',flexDirection:'column'}}>
  <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Address 1</label>
  <textarea type='text' value={userData?.address?.firstLineOfAddress} name='firstLineOfAddress' placeholder='Enter address 1' className='form-control' onChange={handleAddress} />
</div>

<div style={{display:'flex',flexDirection:'column'}}>
  <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Address 2</label>
  <textarea type='text' value={userData?.address?.secondLineOfAddress} name='secondLineOfAddress' placeholder='Enter address 2' className='form-control' onChange={handleAddress} />
</div>

<div style={{display:'flex',flexDirection:'column'}}>
  <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Postal Code</label>
  <input type='text' value={userData?.address?.postalCode} name='postalCode' placeholder='Enter Postal Code' className='form-control' onChange={handleAddress} />
</div>

<div style={{display:'flex',flexDirection:'column'}}>
  <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Town</label>
  <input type='text' value={userData?.address?.town} name='town' placeholder='Enter Town' className='form-control' onChange={handleAddress} />
</div>

<div style={{display:'flex',flexDirection:'column'}}>
  <label style={{fontSize:'0.9rem',fontWeight:'500'}}>State</label>
  <input type='text' value={userData?.address?.county} name='county' placeholder='Enter State' className='form-control' onChange={handleAddress} />
</div>

<div style={{display:'flex',flexDirection:'column'}}>
  <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Country</label>
  <input type='text' value={userData?.address?.country} name='country' placeholder='Enter Country' className='form-control' onChange={handleAddress} />
</div>




</div>



    <h5 style={{margin:'0',padding:'0',color:'#D14774',width:'100%',backgroundColor:'rgba(0,0,0,0.1)',padding:'1rem',borderRadius:'0.3rem',fontSize:'1rem'}}>Bank Details</h5>

    <div style={{margin:'1.3rem 0',display:'flex',gap:'1rem'}}>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Bank Name</label>
      <input type='text' value={userData?.bankDetails?.bank} name='bank' placeholder='Enter Bank Name' className='form-control' onChange={handleBank} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Account Name</label>
      <input type='text' value={userData?.bankDetails?.accountName} name='accountName' placeholder='Enter Account Name' className='form-control' onChange={handleBank} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Account Number</label>
      <input type='text' value={userData?.bankDetails?.accountNumber} name='accountNumber' placeholder='Enter Account Number' className='form-control' onChange={handleBank} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Sort Code</label>
      <input type='text' value={userData?.bankDetails?.sortCode} name='sortCode' placeholder='Enter Sort Code' className='form-control' onChange={handleBank} />
    </div>

    

    </div>


    <h5 style={{margin:'0',padding:'0',color:'#D14774',width:'100%',backgroundColor:'rgba(0,0,0,0.1)',padding:'1rem',borderRadius:'0.3rem',fontSize:'1rem'}}>Statistics Details</h5>

    <div style={{margin:'1.3rem 0',display:'flex',gap:'1rem',flexWrap:'wrap'}}>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Expenses</label>
      <input type='text' value={userData?.statistics?.expenses} name='expenses' placeholder='Enter Expenses' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Total Mileage</label>
      <input type='text' value={userData?.statistics?.totalMileage} name='totalMileage' placeholder='Enter Total Mileage' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Total Monthly Hours</label>
      <input type='text' value={userData?.statistics?.totalMonthlyHours} name='totalMonthlyHours' placeholder='Enter Total Monthly Hours' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Total Pay</label>
      <input type='text' value={userData?.statistics?.totalPay} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Total Visits</label>
      <input type='text' value={userData?.statistics?.totalVisits} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Travel Time</label>
      <input type='text' value={userData?.statistics?.travelTime} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Visits Cancelled</label>
      <input type='text' value={userData?.statistics?.visitsCancelled} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    

    </div>


    
    <h5 style={{margin:'0',padding:'0',color:'#D14774',width:'100%',backgroundColor:'rgba(0,0,0,0.1)',padding:'1rem',borderRadius:'0.3rem',fontSize:'1rem'}}>Visit Analytics</h5>

    <div style={{margin:'1.3rem 0',display:'flex',gap:'1rem',flexWrap:'wrap'}}>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Annual Leave Rate</label>
      <input type='text' value={userData?.visitAnalytics?.early} name='early' placeholder='Enter Expenses' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Bh Rate</label>
      <input type='text' value={userData?.visitAnalytics?.late} name='late' placeholder='Enter Total Mileage' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Christmas Rate</label>
      <input type='text' value={userData?.visitAnalytics?.missed} name='missed' placeholder='Enter Total Monthly Hours' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Frequency</label>
      <input type='text' value={userData?.visitAnalytics?.total} name='total' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>


    

    </div>

    <h5 style={{margin:'0',padding:'0',color:'#D14774',width:'100%',backgroundColor:'rgba(0,0,0,0.1)',padding:'1rem',borderRadius:'0.3rem',fontSize:'1rem'}}>Rate Details</h5>

    <div style={{margin:'1.3rem 0',display:'flex',gap:'1rem',flexWrap:'wrap'}}>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Annual Leave Rate</label>
      <input type='text' value={userData?.rate?.annualLeaveRate} name='expenses' placeholder='Enter Expenses' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Bh Rate</label>
      <input type='text' value={userData?.rate?.bhRate} name='totalMileage' placeholder='Enter Total Mileage' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Christmas Rate</label>
      <input type='text' value={userData?.rate?.christmasRate} name='totalMonthlyHours' placeholder='Enter Total Monthly Hours' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Frequency</label>
      <input type='text' value={userData?.rate?.frequency} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Hourly Rate</label>
      <input type='text' value={userData?.rate?.hourlyRate} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Mileage</label>
      <input type='text' value={userData?.rate?.mileage} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Training Rate</label>
      <input type='text' value={userData?.rate?.trainingRate} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Travel TimeRate</label>
      <input type='text' value={userData?.rate?.travelTimeRate} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    <div style={{display:'flex',flexDirection:'column'}}>
      <label style={{fontSize:'0.9rem',fontWeight:'500'}}>Waiting TimeRate</label>
      <input type='text' value={userData?.rate?.waitingTimeRate} name='totalPay' placeholder='Enter Total pay' className='form-control' onChange={handleChange} />
    </div>

    

    </div>



    
    </div>
    </div>
  )
}

export default UpdateEmployee