import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { Loader, Placeholder } from 'rsuite';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Visit Statistics',
    },
  },
};

export default function ServerVisitCountGraph({titleTemp, graphData}) {
  let graphDataParsed = JSON.parse(graphData);
  console.log("graphData", graphDataParsed['15minutes']);

  const labels = ['Count'];
  
  const data = {
    labels,
    datasets: [
      {
        label: '15 Minutes',
        data: [graphDataParsed['15minutes']],
        backgroundColor: 'rgba(30, 255, 50, 0.5)',
      },
      {
        label: '30 Minutes',
        data: [graphDataParsed['30minutes']],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: '45 Minutes',
        data: [graphDataParsed['45minutes']],
        backgroundColor: 'rgba(53, 53, 52, 0.5)',
      },
      {
        label: '60 Minutes',
        data: [graphDataParsed['60minutes']],
        backgroundColor: 'rgba(120, 53, 52, 0.5)',
      },
      {
        label: '2 Hours',
        data: [graphDataParsed['2hours']],
        backgroundColor: 'rgba(255, 55, 75, 0.5)',
      },
      {
        label: 'Others',
        data: [graphDataParsed['others']],
        backgroundColor: 'rgba(53, 120, 52, 0.5)',
      },
      {
        label: 'Total',
        data: [graphDataParsed['total']],
        backgroundColor: 'rgba(53, 53, 120, 0.5)',
      },
    ],
  };
  console.log("graphData", data);




  // const xyz = {
  //   labels,
  //   datasets: [
  //     {
  //       label: '15 minutes',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(30, 255, 50, 0.5)',
  //     },
  //     {
  //       label: '2 hours',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(255, 55, 75, 0.5)',
  //     },
  //     {
  //       label: '30 minutes',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //     {
  //       label: '45 minutes',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 53, 52, 0.5)',
  //     },
  //     {
  //       label: '60 minutes',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 53, 52, 0.5)',
  //     },
  //     {
  //       label: 'others',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 53, 52, 0.5)',
  //     },
  //     {
  //       label: 'total',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 53, 52, 0.5)',
  //     },
  //   ],
  // };
  // console.log("graphDataxyz", xyz);
  const [loading, setLoading] = useState(false);

  let printDocument = () => {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: "landscape",
          // unit: "in",
          // format: [4, 2]
        });
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("Visit Statistics.pdf");
      })
    ;
  }


  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <div className='container-fluid'>
          <div className='row'>
            <div className='card'>
              <div className="row mt-2 text-right"><i onClick={printDocument} className='fa fa-download'></i></div>
              <div className='card-body' id='divToPrint'>
                <Bar options={options} data={data} />
              </div>
              {/* <div className="container-fluid mb-2 text-center">
                <button className='btn btn-warning btn-sm' onClick={printDocument}><i className='fa fa-print'></i> Print</button>
              </div> */}
            </div>
          </div>
        </div>
      }
    </>
  );
}
