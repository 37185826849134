const countWeekdays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const weekdaysCount = {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    };
  
    while (start <= end) {
      const dayOfWeek = start.toLocaleString('en-US', { weekday: 'long' });
      weekdaysCount[dayOfWeek]++;
      start.setDate(start.getDate() + 1);
    }
  
    return weekdaysCount;
  };
const aggregateWeekdayCounts = (data) => {
    const aggregatedCounts = {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    };
  
    data.forEach(({ leave_start_datetime, leave_end_datetime }) => {
      const counts = countWeekdays(leave_start_datetime, leave_end_datetime);
      for (const day in counts) {
        aggregatedCounts[day] += counts[day];
      }
    });
  
    return aggregatedCounts;
  };

export default aggregateWeekdayCounts;