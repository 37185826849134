import {
    CREATE_SJFMEETINGS,
    RETRIEVE_SJFMEETINGS,
    RETRIEVESINGLE_SJFMEETINGS,
    UPDATE_SJFMEETINGS,
    DELETE_SJFMEETINGS,
  } from "../actions/type";
  const initialState = [];
  function SJFMeetingsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SJFMEETINGS:
        return {data: state.data, payload};
      case RETRIEVE_SJFMEETINGS:
        state = (payload.error === 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SJFMEETINGS:
        state = (payload.error === 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SJFMEETINGS:
        return {data: state.data, payload};
      case DELETE_SJFMEETINGS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SJFMeetingsReducer};