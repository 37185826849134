import http from "../../../http-axios.function";
var sha1 = require('sha1');
// const fetchEquipmentList = (data) => {
//   return http.get(`equipment/equipmentListAjax?ajaxPagin=${data.ajaxPagin}`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }
//   });
// };



// const fetchEquipmentTypeList = (data) => {
//   return http.get(`EquipmentType/equipmentTypeListAjax?ajaxPagin=${data.ajaxPagin}`, {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }
//   });
// };



const getAllCalendarEvents = (_, requestOptions) => {
  const PUBLIC_KEY = 'AIzaSyDMsWqgMNL9oS9Ybh8UXPe08ESoLJW5ZZU',
    CALENDAR_ID = 'sandip.devfreelancer@gmail.com';
  const dataUrl = [ 'https://www.googleapis.com/calendar/v3/calendars/',
    CALENDAR_ID, '/events?key=', PUBLIC_KEY].join('');

  return fetch(dataUrl, requestOptions).then(
    (response) => response.json()
  ).then((data) => data.items);
};


const ReservepageService = {
  // fetchEquipmentList,
  // fetchEquipmentTypeList,
  getAllCalendarEvents,
};


export default ReservepageService;