import React from 'react'
import ReactSession from "../../../components/ReactSession";

function AddLateness() {
  ReactSession.setStoreType("localStorage");
  return (
    <div>AddLateness</div>
  )
}

export default AddLateness