import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { calculateAge } from '../../utils/DateFunctions'
import moment from 'moment';
import ReactSession from "../../components/ReactSession";

export default function Overtime() {
  ReactSession.setStoreType("localStorage");
    const {overtime_id} = useParams();
  const [EmployeeData, setEmployeeData] = useState([]);
    const [formData, setFormData] = useState({
        is_edit: false,
        overtime_id: overtime_id,
        employee_id: '',
        overtime_start_date: '',
        overtime_start_time: '',
        overtime_end_date: '',
        overtime_end_time: '',
        overtime_reward: '',
        overtime_length_in_seconds: '',
        pay_rate: '',
        notes: '',
        server_id: ReactSession.get('server_id')
    });

    useEffect(() => {
        if (overtime_id) {
            fetchOvertime();
        }
        fetchEmployeeDetails();
    }, [overtime_id]);
    const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
        server_id: ReactSession.get('server_id')
    }
  };
    const fetchEmployeeDetails = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_employee_details`, axiosConfig);
      console.log("fetchEmployeeDetails:", response?.data?.data)
      setEmployeeData(response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data : []);
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };
    const fetchOvertime = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_overtimes/fetch_single`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    id: overtime_id,
                    server_id: ReactSession.get('server_id')
                }
            });
            const { employee_id, overtime_start_date, overtime_start_time, overtime_end_date, overtime_end_time, overtime_reward, overtime_length_in_seconds, pay_rate, notes } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { employee_id: '', overtime_start_date: '', overtime_start_time: '', overtime_end_date: '', overtime_end_time: '', overtime_reward: '', overtime_length_in_seconds: '', pay_rate: '', notes: '' };
            setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, employee_id: employee_id, overtime_start_date: moment(overtime_start_date).format('YYYY-MM-DD'), overtime_start_time: overtime_start_time, overtime_end_date: moment(overtime_end_date).format('YYYY-MM-DD'), overtime_end_time: overtime_end_time, overtime_reward: overtime_reward, overtime_length_in_seconds: overtime_length_in_seconds, pay_rate: pay_rate, notes: notes });
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSave = async () => {
        try {
            if (formData?.is_edit) {
                // Update existing employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_overtimes/update`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            } else {
                // Create new employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_overtimes/create`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            }
            alert('OverTime saved successfully!');
        } catch (error) {
            console.error('Error saving employee:', error);
        }
    };

    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card card-primary'>
                    <div className='card-header'>
                        <h4>{formData?.is_edit ? 'Update HR Documentation Appointment' : 'Log Overtime'}</h4>
                    </div>
                    <div className='card-body p-2'>

                        <div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Employess</label>
                                        {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                                        <select className='form-select' id='employee_id' name='employee_id' value={formData?.employee_id} 
                                        onChange={handleChange}>
                                            <option value={''}> - SELECT - </option>
                                            {
                                                EmployeeData && EmployeeData?.length > 0 ? 
                                                EmployeeData.map((val, index) => (
                                                    <option key={index} value={val.id}>{val.full_name} ({val.email_id})</option>
                                                ))
                                                :''
                                            }
                                        </select>
                                    </div>
                                    <div className='col-6'>
                                        <label>OverTime Start Date </label>
                                        <input type="date"
                                        className='form-control'id="overtime_start_date" name='overtime_start_date' value={formData?.overtime_start_date}
                                        onChange={handleChange} />
                                    </div>
                                    <div className='col-6'>
                                        <label>OverTime Start Time</label>
                                        <input type="time"
                                        className='form-control'id="overtime_start_time" name='overtime_start_time' value={formData?.overtime_start_time}
                                        onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-6'>
                                        <label>Overtime Ends Date</label>
                                        <input type="date"
                                        className='form-control'id="overtime_end_date" name='overtime_end_date' value={formData?.overtime_end_date}
                                        onChange={handleChange} />
                                    </div>
                                    <div className='col-6'>
                                        <label>Overtime Ends Time</label>
                                        <input type="time"
                                        className='form-control'id="overtime_end_time" name='overtime_end_time' value={formData?.overtime_end_time}
                                        onChange={handleChange} />
                                    </div>
                                    <div className='col-12'>
                                        <label>Notes</label>
                                        <input type='text' className='form-control'id="notes" name='notes' value={formData?.notes}
                                        onChange={handleChange}  />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Overtime Rewards</label>
        
                                        {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                                        <select className='form-select' id='overtime_reward' name='overtime_reward' value={formData?.overtime_reward} 
                                        onChange={handleChange}>
                                            <option value={''}> - SELECT - </option>
                                            <option value='TOIL'>  TOIL  </option>
                                            <option value='PAYABLE'>  PAYABLE  </option>
                                        </select>
                                    </div>
                                </div>
                             
                        </div>
                        <div className='form-group'>
                            <button
                                className='btn btn-success text-white'
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
