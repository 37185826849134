import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import EmployeeList from './Employee/Index'
import TeamList from './Team/Index'
import AnnualLeaveBalance from "./AnnualLeave/Index";
import ReactSession from "../../components/ReactSession";

import './Employee.css'
function Employees() {
	ReactSession.setStoreType("localStorage");
	const btns = useRef(null);


	const default_btns = [
		{
			id: "Employees",
			link: "manage-employees",
		},
		{
			id: "Manage teams",
			link: "manage-teams",
		},
		{
			id: "Annual leave balance",
			link: "annual-leave-balance",
		},
	];

	const [Panel, setPanel] = useState("Employees");
	const [emp, setemp] = useState([])


	const handleBtns = (btn) => {
		setPanel(btn.id);
	};

	return (
		<div className="emp_p">
			<h2>Employee hub</h2>
			<div className="emp_p_r">
				<div className="btns-emp">
					{default_btns.map((btn, index) => {
						return (
							<>
								<button
									className={`btn-b panel-tab ${Panel === btn.id ? 'active' : ''}`}
									key={index}
									onClick={() => handleBtns(btn)}
									ref={btns}
									id={btn.id}
								>
									{btn.id}
								</button>
							</>
						);
					})}
				</div>

				{Panel === "Employees" ? (
					<EmployeeList />
				) : Panel === "Manage teams" ? (
					<TeamList />
					// ) : Panel === "Vaccinated employees" ? (
					// <VaccinatedEmployees />
				) : Panel === "Annual leave balance" ? (
					<AnnualLeaveBalance />
				) : (
					""
				)}
			</div>
		</div>
	);
}

export default Employees;
