import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendarstyle.css";
import axios from "axios";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import ReactSession from "../../components/ReactSession";


const POSITION_TYPES = [
  'right top',
  'right bottom',
  'left bottom',
  'left top',
  'center center',
  'top left',
  'bottom left',
];
function Maincalendar(props) {
  ReactSession.setStoreType("localStorage");
  const { filter } = props
  const localizer = momentLocalizer(moment);
  const [clockInEvents, setClockInEvents] = useState([]);
  useEffect(() => {
    fetchAllEvents();
  }, []);
  function eventStyleGetter(event, start, end, isSelected) {
    var backgroundColor = "#3174ad";
    if (event.eventName === "clockIn") backgroundColor = "#f5c4d9";
    if (event.eventName === "absense") backgroundColor = "#efe0b2";
    if (event.eventName === "overtime") backgroundColor = "rgb(23 134 48)";
    var style = {
      background: backgroundColor,
      opacity: 0.8,
      color: "black",
    };
    return {
      style: style,
    };
  }
  const fetchAllEvents = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_calander/fetch_all_events`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            server_id: ReactSession.get('server_id'),
          }
        }
      );
      let ar = [];
      if (response?.data && response?.data?.data) {
        if (
          response.data.data?.absense &&
          response.data.data?.absense.length > 0
        ) {
          response.data.data?.absense.map((item) =>
            ar.push({
              title: item.full_name,
              start: new Date(item.leave_start_datetime),
              end: new Date(item.leave_end_datetime),
              eventName: item.leave_for,
              eventData: item,
            })
          );
        }
        if (
          response.data.data?.clockIn &&
          response.data.data?.clockIn.length > 0
        ) {
          response.data.data?.clockIn.map((item) =>
            ar.push({
              title: item.full_name,
              start: new Date(
                new Date(item.start_date).toDateString() + " " + item.start_time
              ),
              end: new Date(
                new Date(item.end_date).toDateString() + " " + item.end_time
              ),
              eventName: "clockIn",
              eventData: item,
            })
          );
        }
        if (
          response.data.data?.overtime &&
          response.data.data?.overtime.length > 0
        ) {
          response.data.data?.overtime.map((item) =>
            ar.push({
              title: item.full_name,
              start: new Date(
                new Date(item.overtime_start_date).toDateString() +
                " " +
                item.overtime_start_time
              ),
              end: new Date(
                new Date(item.overtime_end_date).toDateString() +
                " " +
                item.overtime_end_time
              ),
              eventName: "overtime",
              eventData: item,
            })
          );
        }
      }
      setClockInEvents(ar);
    } catch (error) {
      console.log(error);
    }
  };
  // const handleSelected = (event) => {
  //   console.info("[handleSelected - event] ", event);
  // };
  const EventDisplay = ({ event }) => {
    var backgroundColor = "#3174ad";
    // if (event.eventName === "clockIn") backgroundColor = "#f5c4d9";
    // if (event.eventName === "absense") backgroundColor = "#efe0b2";
    // if (event.eventName === "overtime") backgroundColor = "rgb(23 134 48)";

    if (event.eventName === "casual") backgroundColor = "rgb(99 187 247)";
    if (event.eventName === "sickness") backgroundColor = "rgb(218 163 39)";
    if (event.eventName === "lateness") backgroundColor = "rgb(208 77 86)";
    if (event.eventName === "clockIn") backgroundColor = "#f5c4d9";
    if (event.eventName === "overtime") backgroundColor = "rgb(208 77 86)";
    if (event.eventName === "toil") backgroundColor = "rgb(23 134 48)";
    if (event.eventName === "to-do") backgroundColor = "rgb(113 165 153)";
    if (event.eventName === "holidays") backgroundColor = "rgb(216 1 109)";
    var style = {
      background: backgroundColor,
      opacity: 0.8,
      color: "black",
    };
    const getTotalDuration = (start, end) => {
      const timeStart = moment(start, "HH:mm:ss")
      const timeEnd = moment(end, "HH:mm:ss")
      const duration = moment.duration(timeEnd.diff(timeStart))
      const hours = parseInt(moment.duration(duration).asHours())
      const minutes = parseInt(moment.duration(duration).asMinutes()) - hours * 60;
      const final = (hours === 0 ? '' : hours + ' Hours ') + minutes + ' minutes'
      return final;
    }
    return (
      <Popup
        trigger={
          <div className="rbc-event rbc-event-continues-after" style={style}>
            <div class="rbc-event-content" title={event.title}>{event.title}</div>
          </div>
        }
        position={POSITION_TYPES}
        on={['hover', 'focus']}
      >
        <div className="row align-items-center justify-content-center mt-2">
          <div
            className="d-flex justify-content-center align-items-center col-sm-4 bg-primary"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
            }}
          >
            <p
              style={{
                fontSize: "1.1rem",
                fontWeight: "500",
                color: "white",
              }}
            >
              {event.eventData.full_name
                .replace(
                  /^\s*([a-zA-Z]).*\s+([a-zA-Z])\S+$/,
                  "$1$2"
                )
                .toUpperCase()}
            </p>
          </div>
          <div className="col-sm-8">
            <p
              className="text-primary"
              style={{ fontSize: "1rem", fontWeight: "600" }}
            >
              {event.eventData.full_name}
            </p>
            <span>
              {event.eventName === 'clockIn' || event.eventName === 'overtime' ?
                (moment(event.start).format('LT') + ' - ' + moment(event.end).format('LT'))
                : (moment(event.start).format('ddd D MMM YY') + ' - ' + moment(event.end).format('ddd D MMM YY'))
              }
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-3"></div>
          <div className="col-sm-9 ps-1">
            <p>
              {event.eventName === 'clockIn' ?
                <span class="badge" style={{ backgroundColor: '#f5c4d9', color: '#000' }}>Clocked In</span>
                : event.eventName === 'overtime' ?
                  <span class="badge" style={{ backgroundColor: 'rgb(23 134 48)' }}>Overtime</span>
                  :
                  <><span class="badge" style={{ backgroundColor: '#efe0b2', color: '#000' }}>Absence for</span> <span className="text-capitalize">{event.eventData.absence_for}</span></>
              }
            </p>
            <p className="m-0">
              {event.eventName === 'clockIn' || event.eventName === 'overtime' ?
                (getTotalDuration(event.start, event.end))
                : (Math.round(((event.end - event.start) / (1000 * 60 * 60 * 24)) + 1) + ' Days')
              }

            </p>
            <span class="badge text-bg-success mt-2">
              {event.eventName === 'absense' ? 'Remarks' : 'Notes'}
            </span>
            <p>{event.eventName === 'absense' ? event.eventData.remarks : event.eventData.notes}</p>
          </div>
        </div>
      </Popup>
    );
  };
  const filteredEvents = filter === 'all' ? clockInEvents : clockInEvents.filter(event => event.eventName === filter);
  return (
    <div style={{ marginTop: "0rem" }}>
      <Calendar
        localizer={localizer}
        events={filteredEvents}
        startAccessor="start"
        endAccessor="end"
        className="calendar-style"
        style={{ height: 500 }}
        // onSelectEvent={handleSelected}
        popup={true}
        components={{
          eventWrapper: EventDisplay,
        }}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
}

export default Maincalendar;
