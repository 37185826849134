import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { calculateAge } from '../../utils/DateFunctions'
import ReactSession from "../../components/ReactSession";

export default function Job() {
  ReactSession.setStoreType("localStorage");
    const {job_id} = useParams();
    const [formData, setFormData] = useState({
        is_edit: false,
        job_id: job_id,
        full_name: "",
        role: "",
        known_as: "",
        email_id: "",
        phone_code: "",
        phone_number: "",
        dob: "",
        age: "",
        gender: "MALE",
        religion: "",
        nationality: "",
        server_id: ReactSession.get('server_id')
    });

    useEffect(() => {
        if (job_id) {
            fetchEmployeeDetails();
        }
    }, [job_id]);
    const fetchEmployeeDetails = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/fetch_single_employee_details`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    job_id: job_id,
                    server_id: ReactSession.get('server_id')
                }
            });
            const { full_name, role, known_as, email_id, phone_code, phone_number, dob, age, gender, religion, nationality } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { full_name: '', role: '', known_as: '', email_id: '', phone_code: '', phone_number: '', dob: '', age: '', gender: '', religion: '', nationality: '' };
            setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, full_name: full_name, role: role, known_as: known_as, email_id: email_id, phone_code: phone_code, phone_number: phone_number, dob: dob, age: age, gender: gender, religion: religion, nationality: nationality });
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDob = (e) => {
        // handleChange(e); 
        const { name, value } = e.target;
        const age = calculateAge(e.target.value)
        setFormData({ ...formData, ["age"]: age, [name]: value });
    }

    const handleSave = async () => {
        try {
            if (formData?.is_edit) {
                // Update existing employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/update_employee_details`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            } else {
                // Create new employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_employee/create_employee`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            }
            alert('Employee saved successfully!');
        } catch (error) {
            console.error('Error saving employee:', error);
        }
    };

    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card card-primary'>
                    <div className='card-header'>
                        <h4>{formData?.is_edit ? 'Update HR Documentation Appointment' : 'Request a HR Documentation Appointment'}</h4>
                    </div>
                    <div className='card-body p-2'>

                        <div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-6'>
                                        <label>First Name</label>
                                        <input type='text' className='form-control' placeholder='Enter First Name' required />
                                    </div>
                                    <div className='col-6'>
                                        <label>Last Name</label>
                                        <input type='text' className='form-control' placeholder='Enter Last Name' required />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-6'>
                                        <label>Email Address</label>
                                        <input type='email' className='form-control' placeholder='jane.smith@acmeinc.com' required />
                                    </div>
                                    <div className='col-6'>
                                        <label>Company</label>
                                        <input type='text' className='form-control' placeholder='ACME inc.' required />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Account Number</label>
                                        <input type='number' className='form-control' placeholder='Account number' required />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Telephone Number</label>
                                        <input type='number' className='form-control' placeholder='8956321456' required />
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Appointment type</label>
                                        <select id="inputState" className="form-control">
                                            <option selected>Please select</option>
                                            <option>Specific document request / query</option>
                                            <option>Review of existing documents</option>
                                            <option>HR advice</option>
                                            <option>H&S advice</option>
                                            <option>face-to-face support</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Preferred date( s )</label>
                                        <div className='row align-items-center'>
                                            <div className='col-5'>
                                                <input type='date' className='form-control' />
                                            </div>
                                            <i className="fas fa-arrows-alt-h col-1 text-center" style={{ fontSize: '1.4rem' }}></i>
                                            <div className='col-5'>
                                                <input type='date' className='form-control' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2 mb-2'>
                                    <div className='col-12'>
                                        <label>Other details</label>
                                        <textarea className='form-control' cols='30' rows={4} placeholder='Please enter any other deatils here' />
                                    </div>
                                </div>
                        </div>
                        <div className='form-group'>
                            <button
                                className='btn btn-success text-white'
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
