import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { calculateAge } from '../../utils/DateFunctions'
import ReactSession from "../../components/ReactSession";

export default function CreateLocation() {
    ReactSession.setStoreType("localStorage");
    const { timeoffinlieu_id } = useParams();
    const [formData, setFormData] = useState({
        server_id: ReactSession.get('server_id'),
        is_edit: false,
        timeoffinlieu_id: timeoffinlieu_id,
        id: '',
        location_name: '',
        address: '',
        geofence_radius: '',
        privacy_settings: '',
    });

    useEffect(() => {
        if (timeoffinlieu_id) {
            fetchEmployeeDetails();
        }
    }, [timeoffinlieu_id]);
    const fetchEmployeeDetails = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_clockinlocations/fetch_single`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    server_id: ReactSession.get('server_id'),
                    timeoffinlieu_id: timeoffinlieu_id,
                }
            });
            const { location_name, address, geofence_radius, privacy_settings } = response?.data && response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0] : { location_name: '', address: '', geofence_radius: '', privacy_settings: '' };
            setFormData({ ...formData, is_edit: response?.data && response?.data?.data && response?.data?.data?.length > 0 ? true : false, location_name: location_name, address: address, geofence_radius: geofence_radius, privacy_settings: privacy_settings });
        } catch (error) {
            console.error('Error fetching Clock Location:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSave = async () => {
        try {
            if (formData?.is_edit) {
                // Update existing employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_clockinlocations/update`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            } else {
                // Create new employee
                await axios.post(`${process.env.REACT_APP_NODEAPI_URL}hr_clockinlocations/create`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formData
                });
            }
            alert('Clock Location saved successfully!');
        } catch (error) {
            console.error('Error saving Clock Location:', error);
        }
    };
    return (
        <div className='row'>
            <div className='col-md-6'>
                <div className='card card-primary'>
                    <div className='card-header'>
                        <h4>{formData?.is_edit ? 'Update HR Documentation Appointment' : 'Add Location'}</h4>
                    </div>
                    <div className='card-body p-2'>
                        <div className='row mt-2 mb-2'>
                            <div className='col-12'>
                                <label>Location Name</label>
                                {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                                <input type='text' className='form-control' id="location_name" name='location_name' defaultValue={formData?.location_name}
                                    onChange={handleChange} />
                            </div>
                            <div className='col-12'>
                                <label>Address</label>
                                {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                                <input type='text' className='form-control' id="address" name='address' defaultValue={formData?.address}
                                    onChange={handleChange} />
                            </div>
                            <div className='col-12'>
                                <label>GeoFence Radius (mtrs)</label>
                                {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                                <input type='number' className='form-control' id="geofence_radius" name='geofence_radius' defaultValue={formData?.geofence_radius}
                                    onChange={handleChange} />
                            </div>

                            <div className='col-12'>
                                <label>Privacy setting </label>
                                {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                                <select className='form-select' id='privacy_settings' name='privacy_settings' defaultValue={formData?.privacy_settings}
                                    onChange={handleChange}>
                                    <option value={''}> - SELECT - </option>
                                    <option value={'PUBLIC'}> Public </option>
                                    <option value={'PRIVATE'}> Private </option>
                                </select>
                            </div>
                        </div>
                        <div className='form-group'>
                            <button
                                className='btn btn-success text-white'
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
