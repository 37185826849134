import http from "../../../http-axios.function";


const makeLogin = (data) => {
    return http.post(`/super_admin_login`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};




const findUser = (data) => {
    // return http.post(`http://192.168.29.32:7882/web/`, {
        return http.post(`/web/admin/find`, {
        headers: {
            // 'Content-Type': 'multipart/form-data;charset=UTF-8',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};

const getEmailOtp = (data) => {
    return http.post(`/request_otp`, {
        headers: {
            // 'Content-Type': 'multipart/form-data;charset=UTF-8',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
};

const getVerifyEmailOtp = (data) => {
    return http.post(`/validate_otp`, {
        headers: {
            // 'Content-Type': 'multipart/form-data;charset=UTF-8',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
}

const genarateValidToken = (data) => {
    return http.post(`/validate_token_web`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            // 'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
        }, ...data
    });
}
const SignIn = {
    makeLogin,findUser,getEmailOtp,getVerifyEmailOtp,genarateValidToken
};
export default SignIn;