import React from 'react'
import ReactSession from "../../../components/ReactSession";

function AddOtherAbsence() {
  ReactSession.setStoreType("localStorage");
  return (
    <div>AddOtherAbsence</div>
  )
}

export default AddOtherAbsence