import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactSession from "../../../components/ReactSession";
function Location() {
  ReactSession.setStoreType("localStorage");
  const intialData = {
    server_id: ReactSession.get('server_id'),
    isUpdate: false,
    id: '',
    location_name: '',
    address: '',
    geofence_radius: '',
    privacy_settings: '',
  };
  const [formData, setFormData] = useState([]);
  const modalOpen = React.useRef(null);
  const modalClose = React.useRef(null);
  const [search, setSearch] = useState("");
  const [updateFormData, setUpdateFormData] = useState(intialData);

  useEffect(() => {
    fetchLocation();
  }, []);
  const fetchLocation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODEAPI_URL}hr_clockinlocations/fetch`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            server_id: ReactSession.get('server_id'),
          }
        }
      );
      if (
        response?.data &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        setFormData(response?.data?.data);
      }else{setFormData([])}
    } catch (error) {
      console.error("Error fetching clock in location:", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({ ...updateFormData, [name]: value });
  };
  const handleSave = async () => {
    try {
      if (updateFormData?.isUpdate) {
        await axios.post(
          `${process.env.REACT_APP_NODEAPI_URL}hr_clockinlocations/update`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            data: updateFormData,
          }
        );
        toast.success("Selected Location Update!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      } else {
        await axios.post(
          `${process.env.REACT_APP_NODEAPI_URL}hr_clockinlocations/create`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            data: updateFormData,
          }
        );
        toast.success("New Location Saved!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      modalClose.current?.click();
      fetchLocation();
    } catch (error) {
      toast.error("Failed to Update Location!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.error("Error saving employee:", error);
    }
  };
  const modalEdit = (edit = false, data) => {
      setUpdateFormData({
        isUpdate: edit,
        id: data?.id,
        location_name: data?.location_name,
        address: data?.address,
        geofence_radius: data?.geofence_radius,
        privacy_settings: data?.privacy_settings,
      });
    modalOpen.current?.click();
  };
  const deleteClockins = async (data) =>{
    const isConfirm = window.confirm('Are you sure wanna delete?')
    if(isConfirm){
      await axios.post(
            `${process.env.REACT_APP_NODEAPI_URL}hr_clockinlocations/delete`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              data: {...data, server_id: ReactSession.get('server_id')},
            }
          );
          toast.warning("Location Deleted!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
      fetchLocation();
    }
  }
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  return (
    <div>
      <div className="row mt-3">
        <div className="col-3">
          <input
            placeholder="Search Location"
            className="form-control"
            style={{
              border: "2px solid rgba(0,0,0,0.3)",
              color: "black",
            }}
            onChange={handleSearch}
          />
        </div>
        <div className="col-2">
          <button
            className="btn btn-danger d-none"
            data-bs-toggle="modal"
            data-bs-target="#newLocation"
            ref={modalOpen}
          >
            Add New Location
          </button>
          <button
            className="btn btn-danger"
            onClick={()=>modalEdit(false,intialData)}
          >
            Add New Location
          </button>
          <div
            class={`modal fade`}
            id="newLocation"
            tabindex="-1"
            aria-labelledby="newLocationLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="newLocationLabel">
                    {updateFormData?.isUpdate
                      ? "Update HR Documentation Appointment"
                      : "Add Location"}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row mt-2 mb-2">
                    <div className="col-12">
                      <label>Location Name</label>
                      {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                      <input
                        type="text"
                        className="form-control"
                        id="location_name"
                        name="location_name"
                        defaultValue={updateFormData?.location_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12">
                      <label>Address</label>
                      {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        defaultValue={updateFormData?.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12">
                      <label>GeoFence Radius (mtrs)</label>
                      {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                      <input
                        type="number"
                        className="form-control"
                        id="geofence_radius"
                        name="geofence_radius"
                        defaultValue={updateFormData?.geofence_radius}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-12">
                      <label>Privacy setting </label>
                      {/* <input type='select' className='form-control' placeholder='Enter First Name' required /> */}
                      <select
                        className="form-select"
                        id="privacy_settings"
                        name="privacy_settings"
                        defaultValue={updateFormData?.privacy_settings}
                        onChange={handleChange}
                      >
                        <option value={""}> - SELECT - </option>
                        <option value={"PUBLIC"}> Public </option>
                        <option value={"PRIVATE"}> Private </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    ref={modalClose}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------- */}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 border-bottom border-dark">
          <strng>Location</strng>
        </div>
        <table className="table table-striped">
          <thead>
            <tr style={{ fontSize: "0.9rem" }}>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >Location Name</th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Address
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Geo Radius
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Privacy
              </th>
              <th
                scope="col"
                style={{
                  color: "#073a5d",
                  backgroundColor: "#EBF7FF",
                  borderBottom: "2px solid rgba(0,0,0,0.2)",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="history" style={{ fontSize: "0.9rem" }}>
            {formData && formData.length > 0 ? (
              formData.filter((val) => {
                return search.toLowerCase() === ""
                  ? val
                  : val?.location_name.toLowerCase().includes(search);
              }).map((data, index) => {
                return (
                  <>
                    <tr className="collapsed" key={index}>
                      <td className="text-dark">
                        <p>{data.location_name}</p>
                      </td>
                      <td className="text-dark">
                        <p>{data.address}</p>
                      </td>
                      <td className="text-dark">
                        <p>{data.geofence_radius}</p>
                      </td>
                      <td className="text-dark">
                        <p>{data.privacy_settings}</p>
                      </td>
                      <td className="text-dark">
                        <button className=" btn btn-info" onClick={() => modalEdit(true,data)}>
                          <i className="fa fa-pen"></i>
                        </button>
                        <button className="ms-2 btn btn-primary" onClick={()=>deleteClockins(data)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <th className="text-center" colSpan={5}>
                  No Records Avialable...
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Location;
